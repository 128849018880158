import { Component, OnInit } from '@angular/core';
import {Http} from "@angular/http";
import {ActivatedRoute, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {Common} from "@app/common/common";
import {Utility} from 'app/common/utility';

@Component({
  selector: 'app-emailview',
  templateUrl: './emailview.component.html',
  styleUrls: ['./emailview.component.css']
})
export class EmailviewComponent implements OnInit {

    constructor(private http: Http, private router: Router, private route: ActivatedRoute, private title: Title) {
    }
    Common = Common;
    id: number;
    emailtemplateDetails: object = {};

    ngOnInit() {


        this.title.setTitle('Octanics - Email Template');

        this.route.paramMap.subscribe(params => {
            this.id =  Common.decryptId((params.get('id')));
        });

        this.http.get(Utility.serviceCoreURL + '/emailtemplatedetail?id= ' + this.id).subscribe(data => {
            this.emailtemplateDetails = data.json();
        });
    }

}
