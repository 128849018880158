import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from 'app/common/utility';
import {Customer} from "@app/models/customer.model";
import {FormGroup, Validators, FormControl} from '@angular/forms';
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from '@angular/common/http';
import {Validate} from 'app/common/validate';
import {Lookup} from 'app/common/lookup';
import {DatePipe} from '@angular/common';
import {Common} from "@app/common/common";
import {Title} from '@angular/platform-browser';
import {PopupService} from "@app/common/popup.service";
import {AlertService} from "@app/common/alert.service";
import {isNullOrUndefined} from "util";

declare var $: any;

@Component({
  selector: 'app-updatecontact',
  templateUrl: './updatecontact.component.html',
  styleUrls: ['./updatecontact.component.css']
})
export class UpdatecontactComponent implements OnInit {

    constructor(private http: Http, private title: Title, private router: Router,
                private httpclient: HttpClient, private route: ActivatedRoute,
                private lookUp: Lookup, private datePipe: DatePipe,private alertService: AlertService,
                private popup: PopupService) {
    }
    contactdetail: object = {};
    Validate = Validate;
    ContactForm: FormGroup;
    form: FormGroup;
    contact: Customer;
    customerTypes = [];
    customersocialmedia: object = {};
    cityDetails = [];
    countryDetails = [];
    stateDetails = [];
    username: string;
    id: number;
    emailPattern: string;
    countryCode = [];
    contacts: any;
    subscribe: any;
    subscribes: any;
    business_id = localStorage.getItem('businessid');
    tags: any;
    emailDetails=[];

    onSubscribeCheck(args){
        this.subscribe = args.target.checked;
        if (this.subscribe == true){
            this.subscribes = 1;
        } else{
            this.subscribes = 0;
        }
    }

    getLookUp() {
        this.lookUp.getLookUp('cities').subscribe((data) => {
            if (data) {
                this.cityDetails = data;
            }
        });

        this.lookUp.getLookUp('states').subscribe((data) => {
            if (data) {
                this.stateDetails = data;
            }
        });

        this.lookUp.getLookUp('countries').subscribe((data) => {
            if (data) {
                this.countryDetails = data;
            }
        });
        this.lookUp.getLookUp('customertypes').subscribe((data) => {
            if (data) {
                this.customerTypes = data;
                let type = this.customerTypes.find(x => x.name == 'Contact');
                this.contacts = type['id'];
            }
        });
        this.lookUp.getLookUp('countrycode').subscribe((data) => {
            if (data) {
                this.countryCode = data;
            }
        });
    }

    selectState(args) {
        let countryid = args.target.value;
        this.http.get(Utility.serviceCoreURL + '/getlookup?lookuptype=getstatecountrywise&id=' + countryid).subscribe(data => {
            this.stateDetails = data.json();
        });
    }

    selectCity(args) {
        let stateid = args.target.value;
        this.http.get(Utility.serviceCoreURL + '/getlookup?lookuptype=getcitystatewise&id=' + stateid).subscribe(data => {
            this.cityDetails = data.json();
        });
    }
    emailVerfication(args){
        var email = args.target.value;
        this.http.get(Utility.serviceIntegrationURL() + '/emailverification?email='+email).subscribe(data => {
            this.emailDetails = data.json();
        });
    }
    updateContact(contact) {
        if (this.ContactForm.valid) {
            contact.id = this.id;
            contact.tags = $('#tags').val();
            contact.customertype_id = this.ContactForm.get('customertype_id').value;
            contact.created_by = Utility.getLoggedUserId();
            contact.modified_by = Utility.getLoggedUserId();
            contact.business_id = localStorage.getItem('businessid');
            contact.subscribes = this.subscribes == undefined ? 0 : this.subscribes;
            contact.socialmediacustomerId = this.contactdetail['socialmediacustomerId'];
            contact.deliverability = isNullOrUndefined(this.emailDetails['deliverability'])?this.contactdetail['deliverability']:this.emailDetails['deliverability'];
            contact.qualityscore =  isNullOrUndefined(this.emailDetails['quality_score'])?this.contactdetail['qualityscore']:this.emailDetails['quality_score'];
            this.http.post(Utility.serviceCoreURL + "/updatecustomer", contact).subscribe((res: Response) => {
                if (res) {
                    this.router.navigate(['/contacts']);
                }
            });
        } else {
            this.alertService.validationAlert();
        }
    }

    deletecontact = function (id) {
        if (confirm("Are you sure you want to delete this record?")) {
            this.contactdetail = {
                "id": id,
                "deleted_by": Utility.getLoggedUserId(),
            };
            this.http.post(Utility.serviceCoreURL + "/deletecustomer", this.contactdetail).subscribe((res: Response) => {
                this.router.navigate(['/contacts']);
            });
        }
    };


    ContactFormValidation() {
        let emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
        this.ContactForm = new FormGroup({
            firstname: new FormControl('', Validators.required),
            lastname: new FormControl(null),
            email: new FormControl('', Validators.pattern(emailPattern)),
            customertype_id: new FormControl({value: '', disabled: true}, null),
            title: new FormControl(null),
            companyname: new FormControl(null),
            businessphone: new FormControl(null),
            countryphonecode: new FormControl(null),
            mobilephone: new FormControl('', null),
            homephone: new FormControl(null),
            fax: new FormControl(null),
            street1: new FormControl(null),
            street2: new FormControl(null),
            street3: new FormControl(null),
            city_id: new FormControl(null),
            postcode: new FormControl(null),
            state_id: new FormControl(null),
            country_id: new FormControl(null),
            linkedin: new FormControl(null),
            facebook: new FormControl(null),
            twitter: new FormControl(null),
            instagram: new FormControl(null),
            subscribe: new FormControl('', null),
            tags: new FormControl('', null)
        });
    }

    ngOnInit() {
        $('.navbar-collapse li.active').removeClass('active');
        $('#menusales').addClass('active');

        $(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });

        $('#mobilephone').bind('cut copy paste', function (e) {
            e.preventDefault();
        });

        this.popup.submitPopupEvent
            .subscribe((data: string) => {
                if (data == 'CITY') {
                    this.lookUp.getLookUp('cities').subscribe((data) => {
                        if (data) {
                            this.cityDetails = data;
                        }
                    });
                }

                if (data == 'STATE') {
                    this.lookUp.getLookUp('states').subscribe((data) => {
                        if (data) {
                            this.stateDetails = data;
                        }
                    });
                }

                if (data == 'COUNTRY') {
                    this.lookUp.getLookUp('countries').subscribe((data) => {
                        if (data) {
                            this.countryDetails = data;
                        }
                    });
                }
            });
        this.route.paramMap.subscribe(params => {
            this.id =  Common.decryptId((params.get('id')));
        });

        this.http.get(Utility.serviceCoreURL + '/customerdetails?id=' + this.id).subscribe(data => {
            this.contactdetail = data.json();
            this.tags= this.contactdetail['tags'];
            $('#tags').val(this.tags);
            $('#tags').selectize({
                delimiter: ',',
                persist: false,
                create:function(input ) {
                    return {
                        value: input,
                        text: input
                    };
                }
            });
            this.subscribes = this.contactdetail['subscribe'];
            if (this.subscribes === '1') {
                this.subscribes = true;
            } else {
                this.subscribes = false;
            }
        });
        this.ContactFormValidation();
        this.getLookUp();
        this.title.setTitle('Octanics - Contact');
    }


}
