import {Component, OnInit} from '@angular/core';
import {Http} from '@angular/http';
import {Title} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {Utility} from '@app/common/utility';
import {Angular2Csv} from 'angular2-csv';
import {ActivatedRoute, Router} from "@angular/router";
import {Lookup} from "@app/common/lookup";
import {DatePipe} from "@angular/common";
import {Common} from "@app/common/common";

declare var $: any;

@Component({
  selector: 'app-exportcontact',
  templateUrl: './exportcontact.component.html',
  styleUrls: ['./exportcontact.component.css']
})
export class ExportcontactComponent implements OnInit {

    constructor(private http: Http, private title: Title, private lookUp: Lookup, private datePipe: DatePipe, private route: ActivatedRoute,
                private router: Router) {
    }

    p: Number = 1;
    selected = "all";
    Cities = [];
    States = [];
    exportcontacts = [];
    noDataMessage;
    exportTypes = [];
    csvType: object = {};
    id: number;
    Common = Common;
    areaId: number;
    routes: number;
    rolename: string;
    employeeId: string;
    business_id = localStorage.getItem('businessid');



    getLookUp() {
        this.lookUp.getLookUp('states').subscribe((data) => {
            if (data) {
                this.States = data;
            }
        });
        this.lookUp.getLookUp('cities').subscribe((data) => {
            if (data) {
                this.Cities = data;
            }
        });
        this.http.get(Utility.serviceCoreURL + '/getidbysubarearoute?route=' + this.routes).subscribe((data) => {
            this.areaId = data.json();
            this.lookUp.getLookUp('exporttypes', this.areaId).subscribe((data) => {
                if (data) {
                    this.exportTypes = data;
                    let type = this.exportTypes.find(x => x.code == 'CSV');
                    this.csvType = type['id'];
                }
            });
        });
    }

    getExportContacts(report) {
        this.http.get(Utility.serviceCoreURL + '/exportcontact?search=' +
            report.search + '&startdate=' + report.startdate + '&enddate=' +
            report.enddate + '&cityid=' + report.city_id + '&stateid=' + report.state_id +
            '&business_id=' + this.business_id).subscribe(data => {
            this.exportcontacts = data.json();

            if (this.exportcontacts.length > 0) {
                setTimeout(() => {
                    this.exportContact(this.exportcontacts);
                });
            }
            this.noDataMessage = !this.exportcontacts.length;
        });
    }

    exportContact(exportcontacts) {
        let column = [
            'Customer Type', 'First Name', 'Last Name', 'Email', 'Company Name',
            'Address', 'City', 'State', 'Country', 'Business Phone', 'Mobile Phone'];

        new Angular2Csv(this.exportcontacts, 'contacts', Utility.exportFunction(column));
    }

    ngOnInit() {

        $('.navbar-collapse li.active').removeClass('active');
        $('#menusales').addClass('active');

        this.route.params.subscribe((res) => {
            this.routes = Common.getRoute(this.router.url);
        });

        this.title.setTitle('Octanics - Contact');

        $(document).ready(function () {
            $('#all').click(function () {
                $('.date').hide();
                $('#enddate').val('');
                $('#startdate').val('');
            });

            $('#onemonth').click(function () {
                $('.date').hide();
                $('#enddate').val('');
                $('#startdate').val('');
            });

            $('#threemonth').click(function () {
                $('.date').hide();
                $('#enddate').val('');
                $('#startdate').val('');
            });

            $('#customize').click(function () {
                $('.date').show();
            });
        });

        this.getLookUp();
    }

}
