import {NgModule, ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {FooterComponent} from './shared/footer/footer.component';
import {HeaderComponent} from './shared/header/header.component';
import {LayoutComponent} from './shared/layout/layout.component';
import {LoginComponent} from "./login/login.component";
import {ContactlistComponent} from "@app/audience/contact/contactlist/contactlist.component";
import {InsertcontactComponent} from "@app/audience/contact/insertcontact/insertcontact.component";
import {UpdatecontactComponent} from "@app/audience/contact/updatecontact/updatecontact.component";
import {ContactviewComponent} from "@app/audience/contact/contactview/contactview.component";
import {ExportcontactComponent} from "@app/audience/contact/exportcontact/exportcontact.component";
import {ImportcontactComponent} from "@app/audience/contact/importcontact/importcontact.component";
import {EmailComponent} from "@app/marketing/email/email.component";
import {SmsComponent} from "@app/marketing/sms/sms.component";
import {WhatsappComponent} from "@app/marketing/whatsapp/whatsapp.component";
import {ChangepasswordComponent} from './changepassword/changepassword.component';
import {ProfileComponent} from "./myprofile/profile/profile.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {RedirectComponent} from "./redirect/redirect.component";
import {AuthGuard} from "./auth.guard";
import {SettingsComponent} from './settings/settings.component';
import {CountrylistComponent} from './masters/common/country/countrylist/countrylist.component';
import {InsertcountryComponent} from './masters/common/country/insertcountry/insertcountry.component';
import {UpdatecountryComponent} from './masters/common/country/updatecountry/updatecountry.component';
import {StatelistComponent} from './masters/common/state/statelist/statelist.component';
import {InsertstateComponent} from './masters/common/state/insertstate/insertstate.component';
import {UpdatestateComponent} from './masters/common/state/updatestate/updatestate.component';
import {CitylistComponent} from './masters/common/city/citylist/citylist.component';
import {InsertcityComponent} from './masters/common/city/insertcity/insertcity.component';
import {UpdatecityComponent} from './masters/common/city/updatecity/updatecity.component';
import {DocumenttypelistComponent} from './masters/common/documenttype/documenttypelist/documenttypelist.component';
import {InsertdocumenttypeComponent} from './masters/common/documenttype/insertdocumenttype/insertdocumenttype.component';
import {UpdatedocumenttypeComponent} from './masters/common/documenttype/updatedocumenttype/updatedocumenttype.component';
import {CustomerlistComponent} from "@app/audience/customer/customerlist/customerlist.component";
import {InsertcustomerComponent} from "@app/audience/customer/insertcustomer/insertcustomer.component";
import {UpdatecustomerComponent} from "@app/audience/customer/updatecustomer/updatecustomer.component";
import {CustomerviewComponent} from "@app/audience/customer/customerview/customerview.component";
import {ExportcustomerComponent} from "@app/audience/customer/exportcustomer/exportcustomer.component";
import {ImportcustomerComponent} from "@app/audience/customer/importcustomer/importcustomer.component";
import {EmployerComponent} from './hr/employer/employer.component';
import {InsightdashboardComponent} from './insights/insightdashboard/insightdashboard.component';
import {DocumentsComponent} from "@app/datamanagement/documents/documents.component";
import {ParametersComponent} from "@app/configuration/parameters/parameters.component";
import {CampaignlistComponent} from "@app/insights/campaign/campaignlist/campaignlist.component";
import {TemplatelistComponent} from "@app/datamanagement/templates/templatelist/templatelist.component";
import {InserttemplateComponent} from "@app/datamanagement/templates/inserttemplate/inserttemplate.component";
import {UpdatetemplateComponent} from "@app/datamanagement/templates/updatetemplate/updatetemplate.component";
import {TemplateviewComponent} from "@app/datamanagement/templates/templateview/templateview.component";
import {InsertcampaignComponent} from "@app/insights/campaign/insertcampaign/insertcampaign.component";
import {UpdatecampaignComponent} from "@app/insights/campaign/updatecampaign/updatecampaign.component";
import {CampaignviewComponent} from "@app/insights/campaign/campaignview/campaignview.component";
import {EmployeelistComponent} from "@app/audience/employee/employeelist/employeelist.component";
import {InsertemployeeComponent} from "@app/audience/employee/insertemployee/insertemployee.component";
import {UpdateemployeeComponent} from "@app/audience/employee/updateemployee/updateemployee.component";
import {EmployeeviewComponent} from "@app/audience/employee/employeeview/employeeview.component";
import {BirthdaylistComponent} from "@app/greetings/birthday/birthdaylist/birthdaylist.component";
import {WorkanniversarylistComponent} from "@app/greetings/workanniversary/workanniversarylist/workanniversarylist.component";
import {TurbobusinesscustomerComponent} from "@app/backoffice/processingcenter/turbobusinesscustomer/turbobusinesscustomer.component";
import {SidemenulayoutComponent} from "@app/shared/sidemenulayout/sidemenulayout.component";
import {BusinessComponent} from "@app/configuration/business/business.component";
import {EmaillistComponent} from "@app/datamanagement/templates/email/emaillist/emaillist.component";
import {InsertemailComponent} from "@app/datamanagement/templates/email/insertemail/insertemail.component";
import {UpdateemailComponent} from "@app/datamanagement/templates/email/updateemail/updateemail.component";
import {EmailviewComponent} from "@app/datamanagement/templates/email/emailview/emailview.component";
import {SmslistComponent} from "@app/datamanagement/templates/sms/smslist/smslist.component";
import {InsertsmsComponent} from "@app/datamanagement/templates/sms/insertsms/insertsms.component";
import {UpdatesmsComponent} from "@app/datamanagement/templates/sms/updatesms/updatesms.component";
import {SmsviewComponent} from "@app/datamanagement/templates/sms/smsview/smsview.component";
import {WhatsapplistComponent} from "@app/datamanagement/templates/whatsapp/whatsapplist/whatsapplist.component";
import {InsertwhatsappComponent} from "@app/datamanagement/templates/whatsapp/insertwhatsapp/insertwhatsapp.component";
import {UpdatewhatsappComponent} from "@app/datamanagement/templates/whatsapp/updatewhatsapp/updatewhatsapp.component";
import {WhatsappviewComponent} from "@app/datamanagement/templates/whatsapp/whatsappview/whatsappview.component";
import {ReportComponent} from "@app/report/report/report.component";
import {InsertsmscamapignComponent} from "@app/insights/campaign/insertsmscamapign/insertsmscamapign.component";
import {InsertwhatsappcamapignComponent} from "@app/insights/campaign/insertwhatsappcamapign/insertwhatsappcamapign.component";
import {ClonecampaignComponent} from "@app/insights/campaign/clonecampaign/clonecampaign.component";
import {TelegramComponent} from "@app/marketing/telegram/telegram.component";
import {FacebookComponent} from "@app/socialmedia/facebook/facebook.component";
import {InstagramComponent} from "@app/socialmedia/instagram/instagram.component";
import {LinkedinComponent} from "@app/socialmedia/linkedin/linkedin.component";
import {TwitterComponent} from "@app/socialmedia/twitter/twitter.component";
import {YoutubeComponent} from "@app/socialmedia/youtube/youtube.component";

const appRoutes: Routes = [
    {path: '', component: RedirectComponent},
    {path: 'login', component: LoginComponent},
    {
        path: '',
        component: LayoutComponent,
        children: [
            {path: 'insertsmscampaign', component: InsertsmscamapignComponent, canActivate: [AuthGuard]},
            {path: 'insertwhatsappcampaign', component: InsertwhatsappcamapignComponent, canActivate: [AuthGuard]},
            {path: 'whatsapptemplates', component: WhatsapplistComponent, canActivate: [AuthGuard]},
            {path: 'insertwhatsapptemplate', component: InsertwhatsappComponent, canActivate: [AuthGuard]},
            {path: 'updatewhatsapptemplate/:id', component: UpdatewhatsappComponent, canActivate: [AuthGuard]},
            {path: 'whatsapptemplateview/:id', component: WhatsappviewComponent, canActivate: [AuthGuard]},
            {path: 'smstemplates', component: SmslistComponent, canActivate: [AuthGuard]},
            {path: 'insertsmstemplate', component: InsertsmsComponent, canActivate: [AuthGuard]},
            {path: 'updatesmstemplate/:id', component: UpdatesmsComponent, canActivate: [AuthGuard]},
            {path: 'smstemplateview/:id', component: SmsviewComponent, canActivate: [AuthGuard]},
            {path: 'emailtemplates', component: EmaillistComponent, canActivate: [AuthGuard]},
            {path: 'insertemailtemplate', component: InsertemailComponent, canActivate: [AuthGuard]},
            {path: 'updateemailtemplate/:id', component: UpdateemailComponent, canActivate: [AuthGuard]},
            {path: 'emailtemplateview/:id', component: EmailviewComponent, canActivate: [AuthGuard]},
            {path: 'businesses', component: BusinessComponent, canActivate: [AuthGuard]},
            {path: 'birthdays', component: BirthdaylistComponent, canActivate: [AuthGuard]},
          {path: 'insightreport', component: ReportComponent, canActivate: [AuthGuard]},
            {path: 'workanniversaries', component: WorkanniversarylistComponent, canActivate: [AuthGuard]},
            {path: 'employees', component: EmployeelistComponent, canActivate: [AuthGuard]},
            {path: 'insertemployee', component: InsertemployeeComponent, canActivate: [AuthGuard]},
            {path: 'updateemployee/:id', component: UpdateemployeeComponent, canActivate: [AuthGuard]},
            {path: 'employeeview/:id', component: EmployeeviewComponent, canActivate: [AuthGuard]},
            {path: 'templates', component: TemplatelistComponent, canActivate: [AuthGuard]},
            {path: 'inserttemplate', component: InserttemplateComponent, canActivate: [AuthGuard]},
            {path: 'updatetemplate/:id', component: UpdatetemplateComponent, canActivate: [AuthGuard]},
            {path: 'templateview/:id', component: TemplateviewComponent, canActivate: [AuthGuard]},
            {path: 'campaigns', component: CampaignlistComponent, canActivate: [AuthGuard]},
            {path: 'insertcampaign', component: InsertcampaignComponent, canActivate: [AuthGuard]},
          {path: 'updatecampaign/:id', component: UpdatecampaignComponent, canActivate: [AuthGuard]},
            {path: 'clonecampaign/:id', component: ClonecampaignComponent, canActivate: [AuthGuard]},
            {path: 'campaignview/:id', component: CampaignviewComponent, canActivate: [AuthGuard]},
            {path: 'parameters', component: ParametersComponent, canActivate: [AuthGuard]},
            {path: 'documents', component: DocumentsComponent, canActivate: [AuthGuard]},
            {path: 'insightdashboard', component: InsightdashboardComponent, canActivate: [AuthGuard]},
            {path: 'employer', component: EmployerComponent, canActivate: [AuthGuard]},
            {path: 'customers', component: CustomerlistComponent, canActivate: [AuthGuard]},
            {path: 'insertcustomer', component: InsertcustomerComponent, canActivate: [AuthGuard]},
            {path: 'updatecustomer/:id', component: UpdatecustomerComponent, canActivate: [AuthGuard]},
            {path: 'customerview/:id', component: CustomerviewComponent, canActivate: [AuthGuard]},
            {path: 'exportcustomer', component: ExportcustomerComponent, canActivate: [AuthGuard]},
            {path: 'importcustomerlog', component: ImportcustomerComponent, canActivate: [AuthGuard]},
            {path: 'countries', component: CountrylistComponent, canActivate: [AuthGuard]},
            {path: 'insertcountry', component: InsertcountryComponent, canActivate: [AuthGuard]},
            {path: 'updatecountry/:id', component: UpdatecountryComponent, canActivate: [AuthGuard]},
            {path: 'states', component: StatelistComponent, canActivate: [AuthGuard]},
            {path: 'insertstate', component: InsertstateComponent, canActivate: [AuthGuard]},
            {path: 'updatestate/:id', component: UpdatestateComponent, canActivate: [AuthGuard]},
            {path: 'cities', component: CitylistComponent, canActivate: [AuthGuard]},
            {path: 'insertcity', component: InsertcityComponent, canActivate: [AuthGuard]},
            {path: 'updatecity/:id', component: UpdatecityComponent, canActivate: [AuthGuard]},
            {path: 'documenttypes', component: DocumenttypelistComponent, canActivate: [AuthGuard]},
            {path: 'insertdocumenttype', component: InsertdocumenttypeComponent, canActivate: [AuthGuard]},
            {path: 'updatedocumenttype/:id', component: UpdatedocumenttypeComponent, canActivate: [AuthGuard]},
            {path: 'contacts', component: ContactlistComponent, canActivate: [AuthGuard]},
            {path: 'insertcontact', component: InsertcontactComponent, canActivate: [AuthGuard]},
            {path: 'updatecontact/:id', component: UpdatecontactComponent, canActivate: [AuthGuard]},
            {path: 'contactview/:id', component: ContactviewComponent, canActivate: [AuthGuard]},
            {path: 'exportcontact', component: ExportcontactComponent, canActivate: [AuthGuard]},
            {path: 'importcontactlog', component: ImportcontactComponent, canActivate: [AuthGuard]},
            {path: 'email', component: EmailComponent, canActivate: [AuthGuard]},
            {path: 'sms', component: SmsComponent, canActivate: [AuthGuard]},
            {path: 'whatsapp', component: WhatsappComponent, canActivate: [AuthGuard]},
            {path: 'telegrams', component: TelegramComponent, canActivate: [AuthGuard]},
            {path: 'facebook', component: FacebookComponent, canActivate: [AuthGuard]},
            {path: 'instagram', component: InstagramComponent, canActivate: [AuthGuard]},
            {path: 'linkedin', component: LinkedinComponent, canActivate: [AuthGuard]},
            {path: 'twitter', component: TwitterComponent, canActivate: [AuthGuard]},
            {path: 'youtube', component: YoutubeComponent, canActivate: [AuthGuard]},
            {path: 'changepassword', component: ChangepasswordComponent, canActivate: [AuthGuard]},
            {path: 'profile', component: ProfileComponent, canActivate: [AuthGuard]},
            {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
            {path: 'settings', component: SettingsComponent, canActivate: [AuthGuard]}
        ]
        // , canActivate: [AuthGuard]
    },
    {
        path: '',
        component: SidemenulayoutComponent,
        children: [
            {path: 'turbobusinesscustomers', component: TurbobusinesscustomerComponent, canActivate: [AuthGuard]}
        ]
    },
];

export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);
