import { Component, OnInit } from '@angular/core';
import {Http} from "@angular/http";
import {ActivatedRoute, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {Common} from "@app/common/common";
import {Utility} from 'app/common/utility';

@Component({
  selector: 'app-whatsappview',
  templateUrl: './whatsappview.component.html',
  styleUrls: ['./whatsappview.component.css']
})
export class WhatsappviewComponent implements OnInit {

    constructor(private http: Http, private router: Router, private route: ActivatedRoute, private title: Title) {
    }
    Common = Common;
    id: number;
    whatsapptemplateDetails: object = {};

    ngOnInit() {


        this.title.setTitle('Octanics - WhatsApp Template');

        this.route.paramMap.subscribe(params => {
            this.id =  Common.decryptId((params.get('id')));
        });

        this.http.get(Utility.serviceCoreURL + '/whatsapptemplatedetail?id= ' + this.id).subscribe(data => {
            this.whatsapptemplateDetails = data.json();
        });
    }

}
