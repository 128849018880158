import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {Title} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {Utility} from '@app/common/utility';

declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {


    constructor(private http: HttpClient, private title: Title) {
    }
    configuredCampaignCount = [];
    executedCampaignCount = [];
    draftCampaignCount = [];
    campaignMonthValue: any;
    sentEmailsCount = [];
    sentSMSCount = [];
  sentWhatsAppCount = [];
    emailMonthValue: any;
    customerMonthValue : any;
    contactsCount = [];
    customersCount = [];
    employeesCount = [];
    business_id: any = localStorage.getItem('businessid');

    getEmailMonthValue(event){
        this.emailMonthValue = event.target.value;
        this.getSentEmailsCount();
        this.getSentSMSCount();
      this.getsentWhatsAppCount();
    }
    getSentEmailsCount = function() {
        this.http.get(Utility.serviceCoreURL + '/getsentemailcount?month=' + this.emailMonthValue).subscribe(data => {
            this.sentEmailsCount = data;
        });
    };
    getSentSMSCount = function() {
        this.http.get(Utility.serviceCoreURL + '/getsentsmscount?month=' + this.emailMonthValue).subscribe(data => {
            this.sentSMSCount = data;
        });
    };
  getsentWhatsAppCount = function() {
    this.http.get(Utility.serviceCoreURL + '/getsentwhatsappcount?month=' + this.emailMonthValue).subscribe(data => {
      this.sentWhatsAppCount = data;
    });
  };
    getCampaignMonthValue(event){
        this.campaignMonthValue = event.target.value;
        this.getConfiguredCampaignCount();
        this.getExecutedCampaignCount();
        this.getDraftCampaignCount();
    }
    getConfiguredCampaignCount = function() {
        this.http.get(Utility.serviceCoreURL + '/getconfiguredcampaigncount?business_id=' + this.business_id  + '&month=' + this.campaignMonthValue).subscribe(data => {
            this.configuredCampaignCount = data;
        });
    };
    getExecutedCampaignCount = function() {
        this.http.get(Utility.serviceCoreURL + '/getexecutedcampaigncount?business_id=' + this.business_id  + '&month=' + this.campaignMonthValue).subscribe(data => {
            this.executedCampaignCount= data;
        });
    };
    getDraftCampaignCount = function() {
        this.http.get(Utility.serviceCoreURL + '/getdraftcampaigncount?business_id=' + this.business_id  + '&month=' + this.campaignMonthValue).subscribe(data => {
            this.draftCampaignCount = data;
        });
    };

    getContactCount = function() {
        this.http.get(Utility.serviceCoreURL + '/getcontactscount?business_id=' + this.business_id  + '&month=' +  this.customerMonthValue).subscribe(data => {
            this.contactsCount = data;
        });
    };
    getCustomerCount = function() {
        this.http.get(Utility.serviceCoreURL + '/getcustomerscount?business_id=' + this.business_id  + '&month=' +  this.customerMonthValue).subscribe(data => {
            this.customersCount = data;
        });
    };
    getEmployeesCount = function() {
        this.http.get(Utility.serviceCoreURL + '/getemployeescount?business_id=' + this.business_id  + '&month=' +  this.customerMonthValue).subscribe(data => {
            this.employeesCount = data;
        });
    };
    getCustomerMonthValue(event){
        this.customerMonthValue = event.target.value;
        this.getCustomerCount();
        this.getContactCount();
        this.getEmployeesCount();
    }
  ngOnInit() {

      $('.sidenav li.active').removeClass('active');
      $('#menuDashboard').addClass('active');

      this.getContactCount();
      this.getCustomerCount();
      this.getEmployeesCount();
      this.getConfiguredCampaignCount();
      this.getExecutedCampaignCount();
      this.getDraftCampaignCount();
      this.getSentEmailsCount();
      this.getSentSMSCount();
    this.getsentWhatsAppCount();
      this.title.setTitle('Octanics - Dashboard');
  }

}
