import { Component, OnInit } from '@angular/core';
import {Http} from "@angular/http";
import {ActivatedRoute, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {Common} from "@app/common/common";
import {Utility} from 'app/common/utility';

@Component({
  selector: 'app-templateview',
  templateUrl: './templateview.component.html',
  styleUrls: ['./templateview.component.css']
})
export class TemplateviewComponent implements OnInit {

    constructor(private http: Http, private router: Router, private route: ActivatedRoute, private title: Title) {
    }
    Common = Common;
    id: number;
    templateDetails: object = {};

    ngOnInit() {

        this.title.setTitle('Octanics - Template');

        this.route.paramMap.subscribe(params => {
            this.id =  Common.decryptId((params.get('id')));
        });

        this.http.get(Utility.serviceCoreURL + '/templatedetail?id= ' + this.id).subscribe(data => {
            this.templateDetails = data.json();
        });
    }


}
