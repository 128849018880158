import { Injectable } from '@angular/core';
import {Utility} from './utility';
import {Router} from '@angular/router';
//import {Authorisation} from './authorisation';
import {Localstorage} from './localstorage';
import {HttpClient} from '@angular/common/http';
@Injectable()
export class TokenService {
  private data: any;
  constructor(private http: HttpClient, private router: Router, private Localsecurestorage: Localstorage){

    // this._messageService.currentDetail .subscribe(detail=>{
    //   console.log(detail['token']);  //detail is data you send in shared service
    // });
  }
  logout() {
    let email = localStorage.getItem('email');
    let token = localStorage.getItem('token');
    this.http.get(Utility.serviceAuthorisationURL + '/checktoken?email=' + email + '&token=' + token + '&url=' + Utility.serviceCoreURL).subscribe((res) => {
        if (res['_body'] !== '' && res['_body'] !== null && res['_body'] !== undefined) {
            res = res;
            if (res['success'] == 'true') {
                this.http.post(Utility.serviceAuthorisationURL + '/updatetoken', {id: localStorage.getItem('userid'), url: Utility.serviceCoreURL}).subscribe((data) => {});
            }
            this.Localsecurestorage.clearStorage();
            localStorage.clear();
            this.router.navigate(['/login']);
        }else{
            this.router.navigate(['/login']);
        }
    });
  }
  checkTokenAtLogin(){
    let email = localStorage.getItem('email');
    let token = localStorage.getItem('token');
    this.http.get(Utility.serviceAuthorisationURL + '/checktoken?email=' + email + '&token=' + token + '&url=' + Utility.serviceCoreURL).subscribe((res) => {
        if (res !== '' && res !== null && res !== undefined) {
            res = res;
            if (res['success'] == 'true') {
                this.checkTokenAtAuthenticate();
                if (localStorage.getItem('landingroute')=='null'){
                    this.router.navigate(['/login']);
                }else{
                    this.router.navigateByUrl(localStorage.getItem('landingroute'));
                }
            }
        }
    });
  }
  checkTokenAtAuthGuard() {
    let email = localStorage.getItem('email');
    let token = localStorage.getItem('token');
    this.http.get(Utility.serviceAuthorisationURL + '/authenticatetoken?token=' + localStorage.getItem('token') + '&id=' + localStorage.getItem('userid') + '&url=' + Utility.serviceCoreURL).subscribe((data) => {
        this.data = data;
    });
    this.http.get(Utility.serviceAuthorisationURL + '/checktoken?email=' + email + '&token=' + token + '&url=' + Utility.serviceCoreURL ).subscribe(res => {
        if (res !== '' && res !== null && res !== undefined) {
            res = res;
            if (res['success'] == 'true') {
                this.checkTokenAtAuthenticate();
            } else if (this.data['success'] == 'true') {
                this.checkTokenAtAuthenticate();
            } else {
                alert('You have been signed out because you are currently signed in another device');
                this.router.navigate(['/login']);
            }
        }
    });
  }

  checkTokenAtAuthenticate(){
      this.http.get(Utility.serviceAuthorisationURL + '/authenticatetoken?token=' + localStorage.getItem('token') + '&id=' + localStorage.getItem('userid') + '&url='+ Utility.serviceCoreURL).subscribe((data) => {
          data = data;
          if(data['success']=='true') {
              this.http.post(Utility.serviceAuthorisationURL + '/updatetoken', {id: localStorage.getItem('userid'), token: data['token'] , url: Utility.serviceCoreURL}).subscribe((res) => {
                  localStorage.setItem('token', data['token']);
              });
          }

      });
  }

}
