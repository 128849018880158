import { DatePipe } from '@angular/common';

export class Utility {
  constructor(private  datePipe: DatePipe) { }

     // public static serviceCoreURL = 'http://localhost:8002'; /*local*/
    public static serviceCoreURL = "https://marketdeltaservicecore.octanics.co"; /*server*/

        // public static serviceAuthorisationURL = 'http://localhost:8006'; /*local*/
    public static serviceAuthorisationURL = 'https://marketdeltaserviceauthentication.octanics.co'; /*server*/

   // public static serviceIntegrationURL = 'http://localhost:8007'; /*local*/
    public static serviceStorageURL(){
      // return 'http://localhost:8005';
        return localStorage.getItem('serviceStorageURL');
    }

    public static junctionboxURL() {
        return localStorage.getItem('junctionBoxSubDomainPathUrl') != 'null' ? localStorage.getItem('junctionBoxSubDomainPathUrl') : localStorage.getItem('junctionBoxPathUrl');
    }
    public static getCompanyShortNameURL() {
        return localStorage.getItem('companyshortname');
    }
    public static editorLicenseKey = localStorage.getItem('froalaEditorKey');
    public static getCompanyShortName() {
        return localStorage.getItem('companyshortname');
    }
    public static junctionboxPath() {
        return localStorage.getItem('junctionBoxPathUrl');
    }

    public static junctionBoxSubDomain() {
        return localStorage.getItem('junctionBoxSubDomain');
    }
    public static serviceLoggingURL(){
        return localStorage.getItem('serviceLoggingURL');
    }
    public static  serviceIntegrationURL(){
      // return 'http://localhost:8008';
        return localStorage.getItem('serviceIntegrationURL');
    }

    public static getCurrentDateTime() {
        var datePipe = new DatePipe("en-IN");
        return datePipe.transform(new Date(), 'dd-MM-yyyy HH:mm');
    }
    public static databoxPath() {
        return   localStorage.getItem('dataBoxPathUrl');
    }
    public static getLoggedUser = localStorage.getItem('userid');

    public static getLoggedUserId() {
        return localStorage.getItem('userid');
    }

    public static getDateTime() {
        var datePipe = new DatePipe('en-IN');
        return datePipe.transform(new Date(),  'yyyy-MM-dd HH:mm:ss');
    }
    public static getabstractKey() {
        return localStorage.getItem('abstractKey');
    }
    public static exportFunction(column) {
        var options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true,
            /*showTitle: true,*/
            useBom: true,
            headers: column,
            /*nullToEmptyString: Boolean,*/
        };
        return options;
    }

  public static decimalOnly(event: any) {
    const pattern = /[0-9\+\.\ ]/; // kritika
    // const pattern = /^[0-9]+\.?/; // ashish
    // const pattern = /^[0-9]+(\.[0-9]+)?/;
    // const pattern = /^[-+]?[0-9]+\.[0-9]+$/;
    // const pattern = /^((?:\d|\d{1,3}(?:,\d{3})){0,6})(?:\.\d{1,2}?)?$/;
    const inputChar = String.fromCharCode(event.charCode);
    // (event.keyCode !== 8) &&
    // if (((event.keyCode !== 65 || event.keyCode !== 86 || event.keyCode !== 67) && (event.ctrlKey !== true || event.metaKey !== true)) && (!pattern.test(inputChar))) {

    if ( (event.keyCode !== 8) && (!pattern.test(inputChar))) {
      event.preventDefault();
    }
  }

    public static isOfImageType(extension) {
        let validImageExtensions = ['png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG'];

        return validImageExtensions.includes(extension);
    }

  public static isNumber(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
    public static isNull(data) {
        return data == null || data == '';
    }
    public static isOfImportType(extension) {
        let validImportExtensions = ['csv', 'CSV', 'xls', 'XLS', 'xlsx', 'XLSX'];

        return validImportExtensions.includes(extension);
    }
    public static isUndefined(data) {
        return data == undefined;
    }
    public static getShortText(length: number, text) {
        if ( text === '' || text == null ) {
            return text;
        }
        return text.substring(0, length);
    }
    public static getCompanyName = localStorage.getItem('companyname');

    public static getCompanyMobileNumber = localStorage.getItem('companymobileno');

  public static numberOnly(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
