import { Component, OnInit } from '@angular/core';
import {Http, Response} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Lookup} from "@app/common/lookup";
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Utility} from 'app/common/utility';
import {AlertService} from 'app/common/alert.service';

declare var $: any;

@Component({
  selector: 'app-insertsms',
  templateUrl: './insertsms.component.html',
  styleUrls: ['./insertsms.component.css']
})
export class InsertsmsComponent implements OnInit {

    constructor(private http: Http, private router: Router,
                private title: Title, private route: ActivatedRoute, private lookUp: Lookup, private alertService: AlertService) { }

    public options: Object = {
        imageUploadURL: Utility.serviceStorageURL() + '/uploadeditorimage',
        /*toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
          'insertTable', 'insertLink'],
        toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
          'insertTable', 'insertLink'],
        toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
          'insertTable', 'insertLink'],
        toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
          'insertTable', 'insertLink'],*/
    };
    email;
    emailtext;
    value: number;
    templatetypes = [];
    templates = [];
    Utility = Utility;
    smstemplateForm: FormGroup;
    emailvalue: string;
    smsId;

    getLookUp() {
        this.lookUp.getLookUp('templatetypes').subscribe((data) => {
            if (data) {this.templatetypes = data;
                let type = this.templatetypes.find(x => x.name == 'SMS');
                this.smsId = type['id'];
            }});
    }

    onChange($event) {
        this.emailtext = $event;
    }

    insertTemplate(data) {
        if (this.smstemplateForm.valid) {
            if (this.emailtext != undefined) {
                data.description = this.emailtext;
            }
            data.modified_by = Utility.getLoggedUserId();
            this.http.post(Utility.serviceCoreURL + '/insertsmstemplate', data).subscribe((res: Response) => {
                $('#frmtemplate').get(0).reset();
                this.router.navigate(['smstemplates']);
            });
        } else{
            this.alertService.validationAlert();
        }
    }

    ngOnInit() {

        $('.navbar-collapse li.active').removeClass('active');
        $('#menudatamanagement').addClass('active');

        this.getLookUp();

        this.emailvalue = localStorage.getItem('email');

        this.title.setTitle('Octanics - SMS Template');

        $('#editor').froalaEditor({key: Utility.editorLicenseKey , height: 200,   placeholderText: "<br/>"});

        this.smstemplateForm = new FormGroup({
            templatename: new FormControl('', Validators.required),
            subject: new FormControl('', null),
            description: new FormControl('', null)
        });
    }


}
