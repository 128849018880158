import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {Title} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {Utility} from '@app/common/utility';

declare var $: any;

@Component({
  selector: 'app-insightdashboard',
  templateUrl: './insightdashboard.component.html',
  styleUrls: ['./insightdashboard.component.css']
})
export class InsightdashboardComponent implements OnInit {

    constructor(private http: HttpClient, private title: Title) {
    }

    campaignsCount = [];
    configuredCampaignCount = [];
    executedCampaignCount = [];
    draftCampaignCount = [];
    campaignMonthValue: any;

    getCampaignMonthValue(event){
        this.campaignMonthValue = event.target.value;
        this.getCampaignCount();
        this.getConfiguredCampaignCount();
        this.getExecutedCampaignCount();
        this.getDraftCampaignCount();
    }

    getCampaignCount = function() {
        this.http.get(Utility.serviceCoreURL + '/getcampaignscount?business_id=' + this.business_id  + '&month=' +  this.campaignMonthValue).subscribe(data => {
            this.campaignsCount = data;
        });
    };
    getConfiguredCampaignCount = function() {
        this.http.get(Utility.serviceCoreURL + '/getconfiguredcampaigncount?business_id=' + this.business_id  + '&month=' +  this.campaignMonthValue).subscribe(data => {
            this.configuredCampaignCount = data;
        });
    };
    getExecutedCampaignCount = function() {
        this.http.get(Utility.serviceCoreURL + '/getexecutedcampaigncount?business_id=' + this.business_id  + '&month=' +  this.campaignMonthValue).subscribe(data => {
            this.executedCampaignCount= data;
        });
    };
    getDraftCampaignCount = function() {
        this.http.get(Utility.serviceCoreURL + '/getdraftcampaigncount?business_id=' + this.business_id  + '&month=' +  this.campaignMonthValue).subscribe(data => {
            this.draftCampaignCount = data;
        });
    };
    ngOnInit() {

        $('.sidenav li.active').removeClass('active');
        $('#menuDashboard').addClass('active');

        this.getCampaignCount();
        this.getConfiguredCampaignCount();
        this.getExecutedCampaignCount();
        this.getDraftCampaignCount();

    }


}
