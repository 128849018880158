import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {Title} from '@angular/platform-browser';
import {Utility} from 'app/common/utility';
import * as CryptoJS from 'crypto-js';
import * as XLSX from 'xlsx';
import {Angular2Csv, CsvConfigConsts} from 'angular2-csv';
import {Common} from 'app/common/common';
import {ActivatedRoute, Router} from '@angular/router';
import {Sorting} from 'app/common/sorting';
import {Lookup} from "@app/common/lookup";
import {DatePipe} from '@angular/common';
import {AlertService} from "@app/common/alert.service";
import {HttpClient,HttpHeaders} from "@angular/common/http";
import {isNullOrUndefined, log} from "util";

declare var $: any;

@Component({
    selector: 'app-campaignlist',
    templateUrl: './campaignlist.component.html',
    styleUrls: ['./campaignlist.component.css']
})
export class CampaignlistComponent implements OnInit {

    constructor(private http: Http, private title: Title, private lookUp: Lookup,
                private httpClient: HttpClient, public datePipe: DatePipe,
                private route: ActivatedRoute, private router: Router, private alertService: AlertService) {
    }

    routes: string;
    utility = Utility;
    Sorting = Sorting;
    Common = Common;
    p: Number = 1;
    count: Number = 20;
    search;
  counterbilling: number;
  startdate: number;
    campaignId: number;
    campaignstatusId: number;
    business_id = localStorage.getItem('businessid');
    campaignstatus = [];
    campaignDetails = [];
    emailInfo: object = {};
    smsInfo: object = {};
    campaignInfo: object = {};
    emaildatas: object = {};
    errorEmailMessage;
    errorSMSMessage;
    successEmailMessage = '';
    successSMSMessage = '';
    currentDate: string;
    emailData = [];
    smsData = [];
    campaignsmsInfo: object = {};
    billingcounterInfo: object = {};
    formdata=[];
    url = 'https://api-ssl.bitly.com/v4/shorten';
    filelink;
    customerlogs = [];
    billinglogs = [];
    companyShortName = Utility.getCompanyShortName();
    filterCustomer;
    customer: string;
  billingcounter: any;
  billingMonthValue: any;
    areaId: number;
    junctionbox = Utility.junctionboxPath();
    campaignlogs = [];
  billingcounters= [];
    private data: [];
    whatsappInfo: object = {};
    campaigns: object = {};
    whatsappData = [];
    bulknumbers = [];
  id: number;
  billingcounterdetails: object = {};
    bulkInfo: object = {};
    whatsappkey = localStorage.getItem('whatsappkey');

  getLookUp() {
        this.lookUp.getLookUp('campaignstatus').subscribe((data) => {
            if (data) {
                this.campaignstatus = data;
            }
        });
    }

    getCampaigns() {
        this.http.get(Utility.serviceCoreURL + '/campaigns').subscribe(data => {
            this.campaigns = data.json();
        });
    }

    setCampaignId(campaignId, campaignstatusId) {
        this.campaignId = campaignId;
        this.campaignstatusId = campaignstatusId;
    }

    changeStatus(data) {
        data.campaignId = this.campaignId;
        data.modified_by = Utility.getLoggedUserId();
        this.http.post(Utility.serviceCoreURL + "/changecampaignstatus", data).subscribe((res: Response) => {
            this.getCampaigns();
            $('#frmStatus').get(0).reset();
        });
    }

    getRoute() {
        this.http.get(Utility.serviceCoreURL + '/getidbyarearoute?route=' + this.routes).subscribe((data) => {
            this.areaId = data.json();
        });
    }

    runCampaignWhatsApp(campaign){
      let date = campaign.date;
      let id = campaign.id ;
      let template_id = campaign.template_id;
      let documentpath = campaign.documentpath;
      let templatename = campaign.templatename;
      let created_by = Utility.getLoggedUserId();
      let area_id = this.areaId;
      this.http.get(Utility.serviceCoreURL + '/getcustomercampaignwise?id=' + id + '&template_id=' + template_id).subscribe(data => {
        this.campaignDetails = data.json();
        if (this.campaignDetails.length > 0) {
          this.campaignDetails.forEach((element) => {
            let number = Math.abs(element.countryphonecode + element.mobilephone);
            if (!isNaN(number)) {
              this.campaignInfo = {
                'campaign_id': campaign.id,
                'customer': element,
                'date': date,
                'created_by': created_by,
                'area_id': area_id,
                'communationtype': 'whatsapp',
                'logstatus': 'Success'
              };
              this.customerlogs.push(this.campaignInfo);
              this.whatsappInfo = {
              'customername': element.customername,
              'mobilephone':  number,
              'countryphonecode': Math.abs(element.countryphonecode),
              'companyname': Utility.getCompanyName,
              'companycontactnumber': Math.abs(Number(Utility.getCompanyMobileNumber)),
              'template': element.template,
              'url': 'null',
              'templatetype': 'WhatsApp',
              'module': 'AUD',
            };
              this.whatsappData.push(this.whatsappInfo);
              this.bulknumbers.push(number);
            }
            else {
              this.errorEmailMessage = 'Number not available for' + ' ' + element.customername;
              this.campaignInfo = {
                'campaign_id': campaign.id,
                'customer': element,
                'date': date,
                'created_by': created_by,
                'area_id': area_id,
                'communationtype': 'whatsapp',
                'logstatus': 'NotAvailable'
              };
              this.customerlogs.push(this.campaignInfo);
            }
        });
          let campaignlogdetails = {
            "campaignlogs": this.customerlogs,
          };
          this.http.post(Utility.serviceCoreURL + '/insertcampaignlogs', campaignlogdetails).subscribe(data => {
            this.campaignlogs = data.json();
          });
          this.customerlogs = [];
          this.http.post(Utility.serviceCoreURL + '/getCustomwhatsappTemplate', this.whatsappInfo).subscribe(data => {
            var message = data.json();
            this.bulkInfo = {
              'numbers': this.bulknumbers,
              'text': message.whatsappbody,
              'key': this.whatsappkey
            };
            this.bulknumbers = [];
              this.http.post(Utility.serviceIntegrationURL() + '/whatsappbulkshare',this.bulkInfo).subscribe(data => {
                let datas = data.json();
              });
          });
        };
      });
    }

    runCampaign(campaign) {
        let date = campaign.date;
        let id = campaign.id ;
        let template_id = campaign.template_id;
        let documentpath = campaign.documentpath;
        let templatename = campaign.templatename;
        let created_by = Utility.getLoggedUserId();
        let area_id = this.areaId;
        this.http.get(Utility.serviceCoreURL + '/getcustomercampaignwise?id=' + id + '&template_id=' + template_id).subscribe(data => {
            this.campaignDetails = data.json();
            let id = Common.encryptId(this.junctionbox+documentpath);
            const headers = new HttpHeaders()
                .set('cache-control', 'no-cache')
                .set('content-type', 'application/json')
                .set('Authorization', 'aba7df0a852989a7acddd07bf12c23055334429e');
            const body = {
                long_url: this.junctionbox +'/' + encodeURIComponent(documentpath),
                domain: 'bit.ly'
            };
            this.httpClient.post(this.url, body, {headers: headers}).subscribe((res: Response) => {
                this.filelink = res['link'];
                if (this.campaignDetails.length > 0) {
                    this.campaignDetails.forEach((element) => {
                        let email = element.email;
                        this.customer = element.customername;
                        if (email === '' || email === null) {
                            this.errorEmailMessage = 'Email not available for' + ' ' + element.customername;
                            this.campaignInfo = {
                                'campaign_id': campaign.id,
                                'customer': element,
                                'date': date,
                                'created_by': created_by,
                                'area_id': area_id,
                                'communationtype': 'email',
                                'logstatus': 'NotAvailable'
                            };
                            this.customerlogs.push(this.campaignInfo);
                        } else {
                            this.filterCustomer = this.campaignDetails.filter(customer =>
                                customer.email !== '' && customer.email !== null);
                            this.campaignInfo = {
                                'campaign_id': campaign.id,
                                'customer': element,
                                'date': date,
                                'created_by': created_by,
                                'area_id': area_id,
                                'communationtype': 'email',
                                'logstatus': 'Success'
                            };
                            this.customerlogs.push(this.campaignInfo);
                        }
                    });
                    let campaignlogdetails = {
                        "campaignlogs": this.customerlogs,
                    };

                    this.http.post(Utility.serviceCoreURL + '/insertcampaignlogs', campaignlogdetails).subscribe(data => {
                        this.campaignlogs = data.json();
                    });
                    this.emailInfo = {
                        'customers': this.filterCustomer,
                        'companyname': Utility.getCompanyName,
                        'companycontactnumber': Utility.getCompanyMobileNumber,
                        'template': templatename,
                        'templatetype': 'Email',
                    };

                    this.http.post(Utility.serviceCoreURL + '/getcustomemailtemplate', this.emailInfo).subscribe(data => {
                        this.emailData = data.json();
                        this.emailInfo = {
                            'from': this.emailData['from'],
                            'data': this.emailData['data'],
                            'emailbody': this.emailData['emailbody'],
                            'companyshortname': this.companyShortName,
                            'filepath': this.junctionbox + documentpath,
                            'isattached': documentpath === '' || documentpath === null  ? false : true,
                            'loggingurl': Utility.serviceLoggingURL()
                        };
                        this.http.post(Utility.serviceIntegrationURL() + '/sendbulkemail', this.emailInfo).subscribe((res: Response) => {
                            let data = res.json();

                            let email =data['original'];
                            this.emaildatas={'counter':email['toEmail'].length};
                          let emailcounts =this.emaildatas['counter'];
                          this.updateBillingcounteremail( emailcounts);
                            if (data['success'] === 'true') {
                                this.campaignInfo = {
                                    'campaign_id': campaign.id,
                                    'customer': this.filterCustomer,
                                    'date': date,
                                    'created_by': created_by,
                                    'area_id': area_id,
                                    'communationtype': 'email',
                                    'logstatus': 'Success'
                                };

                                this.successEmailMessage = 'Email sent successfully!';
                                //  this.alertService.validationAlert();
                            } else {
                                this.campaignInfo = {
                                    'campaign_id': campaign.id,
                                    'customer': this.filterCustomer,
                                    'date': date,
                                    'created_by': created_by,
                                    'area_id': area_id,
                                    'communationtype': 'email',
                                    'logstatus': 'Failed'
                                };
                                /*this.http.post(Utility.serviceCoreURL + '/insertcampaignlogs', this.campaignInfo).subscribe(data => {
                                    this.campaignlogs = data.json();
                                });*/
                                this.errorEmailMessage = '';
                                // this.alertService.validationAlert();
                            }
                        });
                        this.http.post(Utility.serviceCoreURL + "/changeexecutecampaignstatus", campaign).subscribe((res: Response) => {
                            this.getCampaigns();
                        });
                    });
                }
            });
        });
    }

    updateBillingcounteremail(counts){
    this.billingcounter = {
      'templatetype': 'Email',
      'created_by':Utility.getLoggedUserId(),
      'executedate': this.currentDate,
      'count': counts,
    }
    this.http.post(Utility.serviceCoreURL + '/updatebillingcounter', this.billingcounter).subscribe(data => {
       data.json();
    });

  }
  convertDade(dateTimeFormat){
    let localDate = new Date(dateTimeFormat + 'UTC');
    localDate.toString();
    return localDate;
  }
  runCampaignSms(campaign) {
    var time = new Date();
    if(time.toLocaleString('en-IN', { hour: 'numeric', hour12: false })< '22'&&
      time.toLocaleString('en-IN', { hour: 'numeric', hour12: false })> '09'){
    let date = campaign.date;
    let id = campaign.id ;
    let template_id = campaign.template_id;
    let documentpath = campaign.documentpath;
    let templatename = campaign.templatename;
    let created_by = Utility.getLoggedUserId();
    let area_id = this.areaId;
    this.http.get(Utility.serviceCoreURL + '/getcustomercampaignwise?id=' + id + '&template_id=' + template_id).subscribe(data => {

      this.campaignDetails = data.json();
      let id = Common.encryptId(this.junctionbox+documentpath);
      const headers = new HttpHeaders()
        .set('cache-control', 'no-cache')
        .set('content-type', 'application/json')
        .set('Authorization', 'aba7df0a852989a7acddd07bf12c23055334429e');
      const body = {
        long_url: this.junctionbox +'/' + encodeURIComponent(documentpath),
        domain: 'bit.ly'
      };
      this.httpClient.post(this.url, body, {headers: headers}).subscribe((res: Response) => {
        this.filelink = res['link'];
        if (this.campaignDetails.length > 0) {
          this.campaignDetails.forEach((element) => {
            let mobilephone = element.mobilephone;
            this.customer = element.customername;
            if (mobilephone === '' || mobilephone === null) {
              this.errorSMSMessage = 'Mobilenumber not available for' + ' ' + element.customername;
              this.campaignsmsInfo = {
                'campaign_id': id,
                'customer': element,
                'date': date,
                'created_by': created_by,
                'area_id': area_id,
                'logstatus': 'NoSMS'
              };
              this.http.post(Utility.serviceCoreURL + '/insertSMSCampaignLogs', this.campaignsmsInfo).subscribe(data => {
                this.campaignlogs = data.json();
              });
            } else {
              this.filterCustomer = this.campaignDetails.filter(customer =>
                customer.mobilephone !== '' && customer.mobilephone !== null);
              this.campaignsmsInfo = {
                'campaign_id': id,
                'customer': element,
                'date': date,
                'created_by': created_by,
                'area_id': area_id,
                'logstatus': 'Success'
              };

              this.http.post(Utility.serviceCoreURL + '/insertSMSCampaignLogs', this.campaignsmsInfo).subscribe(data => {
                this.campaignlogs = data.json();
              });
            }
          });
          this.smsInfo = {
            'customers': this.filterCustomer,
            'companyname': Utility.getCompanyName,
            'companycontactnumber': Utility.getCompanyMobileNumber,
            'url': documentpath!=''? this.filelink : '',
            'template': templatename,
            'templatetype': 'SMS',
          };

          this.http.post(Utility.serviceCoreURL + '/getCustomSmsTemplate', this.smsInfo).subscribe(data => {
            this.smsData  = data.json();
            this.smsInfo = {

              'mobilenumber': this.smsData['mobileNumbers'],
              'smsbody': this.smsData['smsBody'],
              'companyshortname': this.companyShortName,
              'loggingurl': Utility.serviceLoggingURL()
            };
            this.http.post(Utility.serviceIntegrationURL() + '/sendmarketdeltaSMS', this.smsInfo).subscribe((res: Response) => {
              let data = res.json();

              if (data['success'] === 'true') {
                this.campaignsmsInfo = {
                  'campaign_id': id,
                  'customer': this.filterCustomer,
                  'date': date,
                  'created_by': created_by,
                  'area_id': area_id,
                  'logstatus': 'Success'
                };
                this.http.post(Utility.serviceCoreURL + '/insertSMSCampaignLogs', this.campaignsmsInfo).subscribe(data => {
                  this.campaignlogs = data.json();
                });
                this.successSMSMessage = 'SMS sent successfully!';
                //  this.alertService.validationAlert();
              } else {
                this.campaignsmsInfo = {
                  'campaign_id': id,
                  'customer': this.filterCustomer,
                  'date': date,
                  'created_by': created_by,
                  'area_id': area_id,
                  'logstatus': 'Failed'
                };
                this.http.post(Utility.serviceCoreURL + '/insertSMSCampaignLogs', this.campaignsmsInfo).subscribe(data => {
                  this.campaignlogs = data.json();
                });
                this.errorSMSMessage = '';
                // this.alertService.validationAlert();
              }
            });
            this.http.post(Utility.serviceCoreURL + "/changeexecutecampaignstatus", campaign).subscribe((res: Response) => {
              this.getCampaigns();
            });
          });
        }
      });
    });
    }else{
      alert('You Can Send SMS Between 9 AM to 9 PM Only');
    }
  }


    ngOnInit() {
      $('.navbar-collapse li.active').removeClass('active');
        $('#menuinsights').addClass('active');
        this.currentDate = Utility.getCurrentDateTime();
        this.route.params.subscribe((res) => {
            this.routes = this.router.url;
        });
        this.getRoute();
        this.getCampaigns();
        this.getLookUp();
        this.title.setTitle('Octanics - Campaigns');
    }

}
