import {Component, OnInit, NgZone, HostListener} from '@angular/core';
import {Http} from '@angular/http';
import {Router} from '@angular/router';
import {Localstorage} from '@app/common/localstorage';
import {TokenService} from '@app/common/token.service';
import {Utility} from '@app/common/utility';
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from '@angular/common/http';
import {log} from "util";

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private router: Router, private Localsecurestorage: Localstorage,
              private http: Http, private httpclient: HttpClient,
              private Token: TokenService) {
  }
  id: string;
  firstname: string;
  profilephoto: string;
  lastname: string;
  userid: string;
  username: string;
  shortName: string;
  junctionbox: string;
  navItems = [];
  roleId: string;
  businesslogo: string;
  businesses = [];
  business = [];
  business_id: any = localStorage.getItem('businessid');
  businessValue : any;
  isAccessible : string;
  user_id: any = localStorage.getItem('userid');
  role_id: any = localStorage.getItem('roleid');
  // shortName: string;
  getBusiness() {
        this.http.get(Utility.serviceCoreURL + '/getbusinessesbyrole?user_id=' + this.id + '&role_id=' + this.role_id).subscribe(data => {
            this.businesses = data.json();
        });
    }

    onBusinessChange(event) {
        this.businessValue = event.target.value;

        let type = this.businesses.find(x => x.id == this.businessValue);
        this.isAccessible = type['isAccessible'];

        if(this.isAccessible == 'true') {
            if (confirm('Are you sure you want to change the business?')) {
                localStorage.removeItem('businessid');
                localStorage.setItem('businessid', this.business_id);
                this.http.get(Utility.serviceCoreURL + '/getbusinessdetails?business_id=' + this.business_id).subscribe(data => {
                    data = data.json();
                    this.business = data['businessdetails'];
                    if (!Utility.isNull(this.business))
                        this.businesslogo = this.business['businesslogo'];
                });
                if (localStorage.getItem('landingroute') == 'null') {
                    this.router.navigate(['/login']);
                } else {
                    if(localStorage.getItem('landingroute') == this.router.url){
                        window.location.reload();
                    }else{
                        this.router.navigateByUrl(localStorage.getItem('landingroute'));
                    }
                }
            } else {
                setTimeout(() => {
                    this.business_id = localStorage.getItem('businessid');
                }, 2);
            }
        }else{
            alert("You do not have access to this business, please contact administrator.");
            setTimeout(() => {
                this.business_id = localStorage.getItem('businessid');
            }, 1);
        }
    }

  getModules() {
      this.http.get(Utility.serviceCoreURL + '/getmodulearea?role_id=' + this.roleId).subscribe((data) => {
          this.navItems = data.json();
      });
  }
  responsivemenuclosing(routes) {
    if (routes != null && routes != -1) {

      $('.multi-collapse a').click(function () {
        $(".multi-collapse").collapse('hide');
        $(".navbar-collapse").collapse('hide');
      });

      //console.log(routes);
    } else if (routes == -1) {
      $('.sub-collapse a').click(function () {
        // $(".multi-collapse").collapse('show');
        $(".sub-collapse").collapse('hide');
        $(".multi-collapse").collapse('hide');
        $(".navbar-collapse").collapse('hide');
      });

      //console.log('else');
    }

  }

  ngOnInit() {

    this.username = localStorage.getItem('username');
    this.id = this.Localsecurestorage.getStorage('useuserid');
    this.profilephoto = localStorage.getItem('profilephoto');
    this.roleId = this.Localsecurestorage.getStorage('useroleid');
    // this.shortName = this.username.charAt(0)
    this.getModules();
    this.getBusiness();

      this.http.get(Utility.serviceCoreURL + '/getbusinessdetails?business_id=' + this.business_id).subscribe(data => {
          data = data.json();
          this.business = data['businessdetails'];
          if (!Utility.isNull(this.business))
              this.businesslogo = this.business['businesslogo'];
      });

    $('.dropdown').click(function () {
      $('.dropdown').addClass('open');
    });
    this.junctionbox = Utility.junctionboxPath();
    this.username = localStorage.getItem('username');
    this.shortName = this.username.charAt(0);
  }

  onLogout() {
    this.Token.logout();
    localStorage.removeItem('email');
    localStorage.removeItem('username');
    // this.router.navigate(['/login']);
  }

  public activeElement = 1;

  public selectedItem(id) {
    this.activeElement = id;
  }
}
