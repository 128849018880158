export class Businessdocuments {
    id: number;
    business_id: number;
    documenttype_id: string;
    documentname:string;
    otherdocumenttype: string;
    originalfilename: string;
    title:string;
    documentpath: string;
    active: boolean;
    created_by: number;
    created_at: string;
    modified_by: number;
    modified_at: string;
    deleted_by: number;
    deleted_at: string;
    entitystate: string;
}

