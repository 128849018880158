import {Component, Input, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from 'app/common/utility';
import {Title} from '@angular/platform-browser';
import {Common} from 'app/common/common';
import {DatePipe} from '@angular/common';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AlertService} from "@app/common/alert.service";

declare var $: any;

@Component({
    selector: 'app-campaignview',
    templateUrl: './campaignview.component.html',
    styleUrls: ['./campaignview.component.css']
})
export class CampaignviewComponent implements OnInit {
  @Input() isNew: boolean;

    constructor(private http: Http, private router: Router,
                private httpClient: HttpClient, public datePipe: DatePipe,
                private httpclient: HttpClient, private alertService: AlertService,
                private route: ActivatedRoute, private title: Title) {

    }

    Common = Common;
    id: number;
    campaigns: object = {};
    campaigndetails = [];
    campaignlogs = [];
    p: Number = 1;
    count: Number = 5;
    areaId: number;
    routes: number;
    code: string;
    campaignId: number;
    deleteMessage = '';
  customerArg= [];
  email: any;
  customername: any;
  sendWhatsappStatus: boolean = false;
  errorWhatsappMessage = '';
  successWhatsappMessage = '';
  successMessage = '';
  logMessageParameter: object = [];
  whatsappInfo: object = {};
  currentDate: string;
  whatsappData = [];
  companyShortName = Utility.getCompanyShortName();
  whatsAppUrl: any;
  campaign: any;
  campaigndetail: any;
  junctionbox = Utility.junctionboxPath();
  url = 'https://api-ssl.bitly.com/v4/shorten';
  filelink;

  customerDetail(customer){
    this.customerArg = customer;
    this.email = customer.email;
    this.customername = customer.firstname + " " + customer.lastname;
  }

  setWhatsappConfig() {
    if (this.customerArg['mobilephone'] !== null && this.customerArg['mobilephone'] !== '') {
      this.sendWhatsappStatus = true;
      this.campaign = this.getDocumentForWhatsapp(this.campaigndetail);
    } else {
      this.errorWhatsappMessage = 'Sms not available for' + ' ' +  this.customername;
      this.successWhatsappMessage = '';
      this.alertService.validationAlert();
    }
  }

  sendWhatsapp(campaign) {
    let templatename = campaign.templatename;
    let documentpath = this.campaigns['documentpath'];
      this.logMessageParameter = {
        'message': 'Document sent to',
        'customer': this.customername,
        'datetime': this.currentDate,
        'communicationtype': 'WhatsApp',
      };
    let id = Common.encryptId(this.junctionbox+documentpath);
    const headers = new HttpHeaders()
      .set('cache-control', 'no-cache')
      .set('content-type', 'application/json')
      .set('Authorization', 'aba7df0a852989a7acddd07bf12c23055334429e');
    const body = {
      long_url: this.junctionbox +'/' + encodeURIComponent(documentpath),
      domain: 'bit.ly'
    };
    this.httpClient.post(this.url, body, {headers: headers}).subscribe((res: Response) => {
      this.filelink = res['link'];

      this.whatsappInfo = {

        'customername': campaign.customername,
        'mobilephone':campaign.mobilephone,
        'customer_id': campaign.customer_id,
        'countryphonecode': campaign.countryphonecode,
        'companyname': Utility.getCompanyName,
        'companycontactnumber': Utility.getCompanyMobileNumber,
        'template': this.campaigns['templatename'],
        'url': documentpath!=''? this.filelink : '',
        'templatetype': 'WhatsApp',
        'module': 'AUD',
      };

      this.http.post(Utility.serviceCoreURL + '/getCustomwhatsappTemplate', this.whatsappInfo).subscribe(data => {
        this.whatsappData = data.json();
        this.whatsappInfo = {
          'countryphonecode': campaign.countryphonecode,
          'mobilenumber':campaign.mobilephone,
          'whatsappbody': this.whatsappData['whatsappbody'],
          'companyshortname': this.companyShortName,
          'loggingurl': Utility.serviceLoggingURL()
        };
        if (!Utility.isUndefined(this.whatsappInfo['whatsappbody']) && !Utility.isNull(this.whatsappInfo['whatsappbody'])) {
          this.http.post(Utility.serviceIntegrationURL() + '/whatsappshare', this.whatsappInfo).subscribe(data => {
            this.successWhatsappMessage = 'WHATSAPP sent successfully!';
            this.sendWhatsappStatus = false;
            this.errorWhatsappMessage = '';
            this.alertService.validationAlert();
            this.whatsAppUrl = data.json()['url'];
            window.open(this.whatsAppUrl, "_blank");

          });
        }
      });
      });
  }
  getDocumentForWhatsapp(campaigndetail): any {

          this.sendWhatsapp(campaigndetail);

  }

    deleteCustomer = function (id) {
        if (confirm('Are you sure you want to delete this record?')) {
            let campaignDetail = {
                "id": id,
                "deleted_by": Utility.getLoggedUserId(),
            };
            this.http.post(Utility.serviceCoreURL + "/deletecampaigndetailscustomer", campaignDetail).subscribe((res: Response) => {
                this.deleteMessage = 'Record Deleted Successfully';
                this.alertService.validationAlert();
                this.getCampaignDetails();
            });
        }
    };

   getCampaignDetails (){
       this.http.get(Utility.serviceCoreURL + '/campaigndetails?id=' + this.id).subscribe(data => {
           data = data.json();
           this.campaigns = data['campaigns'];
           this.campaignId = data['campaigns']['id'];
           this.campaigndetails = data['campaigndetails'];
           this.code = 'CAMP';
           this.http.get(Utility.serviceCoreURL + '/getcampaignlogs?code=' + this.code + '&campaign_id=' + this.campaignId).subscribe(data => {
               this.campaignlogs = data.json();
           });
       });

   }
    ngOnInit() {
        $('.navbar-collapse li.active').removeClass('active');
        $('#menusales').addClass('active');

        this.route.paramMap.subscribe(params => {
            this.id = Common.decryptId((params.get('id')));
        });
        this.route.params.subscribe((res) => {
            this.routes = Common.getRoute(this.router.url);
        });
        this.http.get(Utility.serviceCoreURL + '/getidbysubarearoute?route=' + this.routes).subscribe((data) => {
            this.areaId = data.json();
        });
        this.getCampaignDetails();
        this.title.setTitle('Octanics - Campaign');

    }

}
