import { Component, OnInit } from '@angular/core';
import {Http, Response} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Lookup} from "@app/common/lookup";
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Utility} from 'app/common/utility';
import {AlertService} from 'app/common/alert.service';
import {Common} from "@app/common/common";

declare var $: any;

@Component({
  selector: 'app-updatewhatsapp',
  templateUrl: './updatewhatsapp.component.html',
  styleUrls: ['./updatewhatsapp.component.css']
})
export class UpdatewhatsappComponent implements OnInit {

    constructor(private http: Http, private router: Router,
                private title: Title, private route: ActivatedRoute, private lookUp: Lookup, private alertService: AlertService) { }

    public options: Object = {
        imageUploadURL: Utility.serviceStorageURL() + '/uploadeditorimage',
        /*toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
          'insertTable', 'insertLink'],
        toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
          'insertTable', 'insertLink'],
        toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
          'insertTable', 'insertLink'],
        toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
          'insertTable', 'insertLink'],*/
    };
    id: number;
    email;
    emailtext;
    value: number;
    templatetypes = [];
    templates = [];
    Utility = Utility;
    whatsapptemplateForm: FormGroup;
    emailvalue: string;
    whatsapptemplateDetails = [];
    whatsappId;

    getLookUp() {
        this.lookUp.getLookUp('templatetypes').subscribe((data) => {
            if (data) {this.templatetypes = data;
                let type = this.templatetypes.find(x => x.name == 'WhatsApp');
                this.whatsappId = type['id'];
            }});
    }

    onChange($event) {
        this.emailtext = $event;
    }


    updateTemplate(data) {
        if (this.whatsapptemplateForm.valid) {
            data.modified_by = Utility.getLoggedUserId();
            data.id = this.id;
            this.http.post(Utility.serviceCoreURL + '/updatewhatsapptemplate', data).subscribe((res: Response) => {
                $('#frmtemplate').get(0).reset();
                this.router.navigate(['whatsapptemplates']);
            });
        } else {
            this.alertService.validationAlert();
        }
    }

    deleteTemplate(id) {
        if (confirm('Are you sure you want to delete this record?')) {
            const deletetemplate = {
                'id': id,
                'deleted_by': Utility.getLoggedUserId(),
            };
            this.http.post(Utility.serviceCoreURL + '/deletewhatsapptemplate', deletetemplate).subscribe((res: Response) => {
                this.router.navigate(['/whatsapptemplates']);
            });
        }
    }


    ngOnInit() {

        $('.navbar-collapse li.active').removeClass('active');
        $('#menudatamanagement').addClass('active');


        this.getLookUp();

        this.route.paramMap.subscribe(params => {
            this.id =  Common.decryptId((params.get('id')));
        });

        this.emailvalue = localStorage.getItem('email');

        this.title.setTitle('Octanics - WhatsApp Template');

        $('#editor').froalaEditor({key: Utility.editorLicenseKey , height: 200,   placeholderText: "<br/>"});

        this.http.get(Utility.serviceCoreURL + '/whatsapptemplatedetail?id=' + this.id).subscribe(data => {
            this.whatsapptemplateDetails = data.json();
        });

        this.whatsapptemplateForm = new FormGroup({
            templatename: new FormControl('', Validators.required),
            subject: new FormControl('', null),
            description: new FormControl('', null)
        });
    }


}
