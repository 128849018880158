import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Http, Response} from "@angular/http";
import {ActivatedRoute, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {Lookup} from "@app/common/lookup";
import {Utility} from 'app/common/utility';
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from "@angular/common/http";
import {Common} from "@app/common/common";
import {AlertService} from "@app/common/alert.service";
import {Document} from "@app/models/document.model";

class ImageSnippet {
    constructor(public src: string, public file: File) {
    }
}

declare var $: any;


@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})

export class DocumentsComponent implements OnInit {

    constructor(private http: Http,
                private router: Router,
                private title: Title,
                private httpclient: HttpClient,
                private route: ActivatedRoute,
                private lookUp: Lookup,
                private alertService: AlertService) {
    }
     documentTypes = [];
     selectedFileOriginalName: string;
     DocumentType = null;
    // business: object = [];
     junctionbox = Utility.junctionboxPath();
     junctionBoxSubDomain = Utility.junctionBoxSubDomain();
     companyShortName = Utility.getCompanyShortName();
     business_id: any = localStorage.getItem('businessid');
     uploadingFile = false;
     uploadingDocumentPercents = 0;
     selectedDocumentFile: ImageSnippet;
     selectedDocumentFilePath: string = null;
     Utility = Utility;
     DocumentForm: FormGroup;
     DocumentsForm: FormGroup;
     Documents: Array<Document> = [];
     documentsValidation: boolean;
     documentEditIndex: number = -1;
     document_id: number;
     successMessage = '';
     areaId: number;
     routes: string;
     errorMessage = '';
     documentErrorMessage = '';
     documentValidationMessage: string = '';
     documentSizeLimit = localStorage.getItem('docsizelimit');
     documentFileSizeLimit = this.formatBytes(this.documentSizeLimit);


    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    getLookUp() {
        this.http.get(Utility.serviceCoreURL + '/getidbyarearoute?route=' + this.routes).subscribe((data) => {
            this.areaId = data.json();
            this.lookUp.getLookUp('documenttypes', this.areaId).subscribe((data) => {if (data) {this.documentTypes = data;}
            });
        });
    }

    uploadDocument(event) {
        if (event.target.files.length > 0) {
            if (event.target.files[0].size <= this.documentSizeLimit) {
                this.documentValidationMessage = '';
                if (event.target.files) {
                    for (let i = 0; i < event.target.files.length; i++) {
                        let reader = new FileReader();
                        reader.readAsDataURL(event.target.files[i]);
                        reader.onload = (events: any) => {
                            this.selectedFileOriginalName = event.target.files[i].name;
                            this.selectedDocumentFile = new ImageSnippet(events.target.result[i], event.target.files[i]);
                            this.uploadDocuments(this.selectedDocumentFile.file);
                        };
                    }
                }
            } else {
                this.selectedDocumentFilePath = null;
                this.documentsValidation = true;
                this.selectedFileOriginalName = null;
                this.documentValidationMessage = 'Please upload document as per given size';
                this.alertService.validationAlert();
            }
        } else {
            this.selectedDocumentFilePath = null;
            this.selectedFileOriginalName = null;
            this.documentsValidation = true;
        }
    }

    public uploadDocuments(document: any) {
        const formData = new FormData();
        formData.append('file', document);
        formData.append('filename', document.name);
        formData.append('filepath', this.companyShortName.replace(/\s/g, '').toLowerCase() + '/assets/businessdocuments');
        formData.append('subdomain', this.junctionBoxSubDomain);

        let req = new HttpRequest('POST', Utility.serviceStorageURL() + '/uploadfile', formData, {
            reportProgress: true
        });

        this.httpclient.request(req).subscribe(event => {
            this.selectedDocumentFilePath = event['body'];
            // Via this API, you get access to the raw event stream.
            if (event.type === HttpEventType.UploadProgress) {
                // This is an upload progress event. Compute and show the % done:
                this.uploadingDocumentPercents = Math.round(100 * event.loaded / event.total);
                this.uploadingFile = true;
                // Look for upload progress events.
            } else if (event instanceof HttpResponse) {
                this.uploadingFile = false;
            }
        });
    }

    insertDocuments() {
        if (!Utility.isNull(this.selectedDocumentFilePath && this.documentValidationMessage.length <= 0)) {
            if (this.DocumentsForm.controls.documenttype_id.valid) {
                this.documentsValidation = false;
                this.insertDocument();
            }
        } else {
            if (this.DocumentsForm.controls.documenttype_id.valid && this.DocumentsForm.controls.document.valid && this.documentValidationMessage.length <= 0) {
                this.documentsValidation = false;
                this.insertDocument();
            } else {
                this.documentsValidation = true;
                this.alertService.validationAlert();
            }
        }
    }

    insertDocument() {
        let document = this.documentTypes.filter(x => x.id == this.DocumentType);
        if (this.documentEditIndex !== undefined && this.documentEditIndex >= 0) {
            this.Documents[this.documentEditIndex].documenttype_id = this.DocumentType;
            this.Documents[this.documentEditIndex].documentname = document[0].name;
            this.Documents[this.documentEditIndex].documentpath = this.selectedDocumentFilePath;

            if (this.document_id > 0)
                this.Documents[this.documentEditIndex].entitystate = 'Modified';
            else
                this.Documents[this.documentEditIndex].entitystate = 'Added';
        } else {
            let customObj = new Document();
            customObj.documenttype_id = this.DocumentType;
            customObj.documentname = document[0].name;
            customObj.documentpath = this.selectedDocumentFilePath;
            customObj.entitystate = 'Added';
            this.Documents.push(customObj);
        }
        this.clearDocumentControls();
    }

    editDocument(i, editdata) {
        this.document_id = editdata.id;
        this.DocumentType = editdata.documenttype_id;
        this.selectedDocumentFilePath = editdata.documentpath;
        this.documentEditIndex = i;

        if (!Utility.isNull(this.selectedDocumentFilePath)) {
            $('.showDocPreview').show();
            this.documentsValidation = false;
        } else {
            $('.showDocPreview').hide();
        }
        // this.clearDocumentControls();
    }

    deleteDocument(id) {
        if (confirm('Are you sure you want to delete this record?')) {
            if (this.Documents[id].id != undefined) {
                this.Documents[id].entitystate = 'Deleted';
            } else {
                this.Documents.splice(id, 1);
            }
        }
    }

    clearDocumentControls() {
        $('#frmDocumentsUpload').get(0).reset();
        this.DocumentType = null;
        $('.showDocPreview').hide();
        this.document_id = null;
        this.selectedFileOriginalName = null;
        this.selectedDocumentFilePath = null;
        this.documentEditIndex = -1;
        $('#document').val('');
    }

    saveDocument(document) {
        /*this.errorMessage = this.Documents.filter(x => x.entitystate !== 'Deleted').length;
        if (this.errorMessage == 0) {
            this.documentErrorMessage = 'Please select at least one Document';
        }*/
        if (this.DocumentForm.valid) {
            this.documentErrorMessage = '';
            document.documents = this.Documents;
            document.created_by = document.modified_by  = Utility.getLoggedUserId();
            this.http.post(Utility.serviceCoreURL + '/updatedocument', document).subscribe((res: Response) => {
                if (res) {
                    this.successMessage = 'Document updated successfully';
                    this.getDocumentDetails();
                    this.clearDocumentControls();
                    this.alertService.validationAlert();
                }
            });
        } else {
            this.alertService.validationAlert();
        }
    }

    getDocumentDetails() {
        this.http.get(Utility.serviceCoreURL + '/getdocumentdetails').subscribe(data => {
            this.Documents = data.json();
        });
    }

    ngOnInit() {

        $('.navbar-collapse li.active').removeClass('active');
        $('#menudatamanagement').addClass('active');


        this.title.setTitle('Octanics - Documents');

        this.route.params.subscribe((res) => {
            this.routes = Common.getRoute(this.router.url);
        });

        this.getLookUp();

        this.DocumentsForm = new FormGroup({
            documenttype_id: new FormControl('', Validators.required),
            document: new FormControl('', Validators.required)
        });

       this.DocumentForm = new FormGroup({
         });

        this.getDocumentDetails();

        if (this.junctionBoxSubDomain == 'null') {
            this.junctionBoxSubDomain = null;
        }
     }

}
