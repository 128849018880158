export class Document {
    id:number;
    documenttype_id: number;
    documentpath:string;
    documentname: string;
    active:boolean;
    created_by:number;
    created_at: string;
    modified_by:number;
    modified_at: string;
    deleted_by:number;
    deleted_at: string;
    entitystate: string;
}
