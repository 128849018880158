import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Utility} from 'app/common/utility';
import {Http, Response, Headers} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Validate} from 'app/common/validate';
import {Sorting} from 'app/common/sorting';
import {Common} from "@app/common/common";

declare var $: any;

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.css']
})
export class ParametersComponent implements OnInit {


    constructor(private http: Http, private title: Title, private router: Router,
                private route: ActivatedRoute) {
    }

    parameters: object = [];
    id: number;
    Validate = Validate;
    searchText;
    p: Number = 1;
    Common = Common;
    count: Number = 20;
    Sorting = Sorting;
    order: boolean = true;
    property: string = null;

    sorting(property, obj) {
        if (property == this.property) {
            this.property = property;
            this.order = !this.order;
            if (this.order) {
                $('.table-borderless thead th i').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
                $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-up').css('color', '1E2859');
            } else {
                $('.table-borderless thead th i').removeClass('fad fa-sort-up').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
                $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-down').css('color', '1E2859');
            }
            Sorting.sort(property, obj, this.order);
        } else {
            this.property = property;
            this.order = false;
            $('.table-borderless thead th i').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
            $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-down').css('color', '1E2859');
            Sorting.sort(property, obj, this.order);
        }
    }


    getParameters = function() {
        this.http.get(Utility.serviceCoreURL + '/parameters').subscribe(data => {
            this.parameters = data.json();
        });
    };

    ngOnInit() {
        $('.navbar-collapse li.active').removeClass('active');
        $('#menuplatfromsettings').addClass('active');
        this.getParameters();
        this.title.setTitle('Octanics - Parameters');
    }

}
