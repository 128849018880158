import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import {Utility} from 'app/common/utility';
import {map} from "rxjs/operators";
@Injectable()
export class Emailverification {
    constructor(private http: Http) {
        console.log(Utility.getabstractKey());
    }
    emailVerified(email){
        return this.http.get(Utility.serviceIntegrationURL() + '/emailverification?key='+Utility.getabstractKey()+'&email='+email).pipe(map(res => res.json()));
    }
}