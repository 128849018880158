import {Component, Input, OnInit} from '@angular/core';
import {Http, Response, Headers, ResponseContentType} from '@angular/http';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {Utility} from 'app/common/utility';
import {Customer} from 'app/models/customer.model';
import {Common} from 'app/common/common';
import {ActivatedRoute, Router} from '@angular/router';
import {Sorting} from 'app/common/sorting';
import {Lookup} from "@app/common/lookup";
import {HttpClient, HttpHeaders, HttpRequest} from "@angular/common/http";
import {AlertService} from "@app/common/alert.service";
import {DatePipe} from "@angular/common";
declare var $: any;

@Component({
    selector: 'app-contactlist',
    templateUrl: './contactlist.component.html',
    styleUrls: ['./contactlist.component.css']
})
export class ContactlistComponent implements OnInit {

  @Input() isNew: boolean;
    private type: string;

    constructor(private http: Http, private title: Title,
                private sanitizer: DomSanitizer, private router: Router,
                private route: ActivatedRoute, private lookUp: Lookup,
                private httpClient: HttpClient, public datePipe: DatePipe,
                private httpclient: HttpClient, private alertService: AlertService) {
    }
    currentDate: string;
    utility = Utility;
    Sorting = Sorting;
    Common = Common;
    p: Number = 1;
    count: Number = 20;
    search;
    customer: Customer;
    contacts = [];
    exportcustomers = [];
    file: File;
    value: string;
    routes: string;
    areaId: object = {};
    order: boolean = true;
    property: string = null;
    business_id = localStorage.getItem('businessid');
    rolename: string ;
    employeeId: string ;
    documenttypes = [];
    sendMailStatus: boolean = false;
    documents = [];
    document:any;
    logMessageParameter: object = [];
    errorEmailMessage = '';
    successEmailMessage = '';
    successMessage = '';
    emailInfo: object = {};
    emailData = [];
    customerArg = [];
    junctionbox = Utility.junctionboxPath();
    junctionBoxSubDomain = Utility.junctionBoxSubDomain();
    companyShortName = Utility.getCompanyShortName();
    DocumentPDFPath: any;
    id: number;
    email: any;
    customername: any;
  smsData = [];
  whatsappData = [];
  url = 'https://api-ssl.bitly.com/v4/shorten';
  filelink;
  whatsappfileink;
  whatsAppUrl: any;
  formdata = [];
  sendSmsStatus: boolean = false;
  sendWhatsappStatus: boolean = false;
  errorSmsMessage = '';
  errorWhatsappMessage = '';
  successSmsMessage = '';
  successWhatsappMessage = '';
  smsInfo: object = {};
  whatsappInfo: object = {};
    data=[];
  billingcounter: any;


    getLookUp() {
        this.lookUp.getLookUp('documenttypes').subscribe((data) => {
            if (data) {
                this.documenttypes = data;
            }
        });
    }

    sorting(property, obj) {
        if (property == this.property) {
            this.property = property;
            this.order = !this.order;
            if (this.order) {
                $('.table-borderless thead th i').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
                $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-up').css('color', '1E2859');
            } else {
                $('.table-borderless thead th i').removeClass('fad fa-sort-up').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
                $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-down').css('color', '1E2859');
            }
            Sorting.sort(property, obj, this.order);
        } else {
            this.property = property;
            this.order = false;
            $('.table-borderless thead th i').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
            $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-down').css('color', '1E2859');
            Sorting.sort(property, obj, this.order);
        }
    }


    getContact() {
        this.http.get(Utility.serviceCoreURL + '/contacts?business_id=' + this.business_id).subscribe(data => {
            this.contacts = data.json();
        });
    }
    customerDetail(customer){
        this.customerArg = customer;
        this.email = customer.email;
        this.customername = customer.firstname + " " + customer.lastname;
    }


  setMailConfig() {
    if (this.customerArg['email'] !== null && this.customerArg['email'] !== '') {
      this.sendMailStatus = true;
      this.document = this.getDocumentForMail();
    } else {
      this.errorEmailMessage = 'Email not available for' + ' ' +  this.customername;
      this.successEmailMessage = '';
      this.alertService.validationAlert();
    }
  }
  setSmsConfig() {
    if (this.customerArg['mobilephone'] !== null && this.customerArg['mobilephone'] !== '') {
      this.sendSmsStatus = true;
      this.document = this.getDocumentForSms();
    } else {
      this.errorSmsMessage = 'Sms not available for' + ' ' +  this.customername;
      this.successSmsMessage = '';
      this.alertService.validationAlert();
    }
  }
  setWhatsappConfig() {
    if (this.customerArg['mobilephone'] !== null && this.customerArg['mobilephone'] !== '') {
      this.sendWhatsappStatus = true;
      this.document = this.getDocumentForWhatsapp();
    } else {
      this.errorWhatsappMessage = 'Sms not available for' + ' ' +  this.customername;
      this.successWhatsappMessage = '';
      this.alertService.validationAlert();
    }
  }

  getDocumentForSms(): any {

    this.id = $('#documenttype_id option:selected').val();

    if ($('#documenttype_id option:selected').val() != null) {
      this.http.get(Utility.serviceCoreURL + '/documentcommunication?id=' + this.id).subscribe(
        (res) => {
          this.documents = res.json();
          this.document = {
            'documentpath': this.documents['documentpath'],
            'documenttype': this.documents['documentname'],
          };
          this.sendSMS(this.document);

        });
    }
  }
  getDocumentForWhatsapp(): any {
    this.id = $('#documenttype_id option:selected').val();
    if ($('#documenttype_id option:selected').val() != null) {
      this.http.get(Utility.serviceCoreURL + '/documentcommunication?id=' + this.id).subscribe(
        (res) => {
          this.documents = res.json();
          this.document = {
            'documentpath': this.documents['documentpath'],
            'documenttype': this.documents['documentname'],
          };
          this.sendWhatsapp(this.document);
        });
    }
  }
  getDocumentForMail(): any {
    this.id = $('#documenttype_id option:selected').val();
    if ($('#documenttype_id option:selected').val() != null) {

      this.http.get(Utility.serviceCoreURL + '/documentcommunication?id=' + this.id).subscribe(
        (res) => {
          this.documents = res.json();
          this.document = {
            'documentpath': this.documents['documentpath'],
            'documenttype': this.documents['documentname'],
          };
          this.sendMail(this.document);
        });
    }
  }

  sendMail(document) {

    this.logMessageParameter = {
      'message': 'Document sent to',
      'customer': this.customername,
      'datetime': this.currentDate,
      'communicationtype': 'Email',
    };
    this.emailInfo = {

      'customername': this.customername,
      'email':this.customerArg['email'],
      'customer_id': this.customerArg['customer_id'],
      'documenttype': this.documents['documentname'],
      'companyname': Utility.getCompanyName,
      'companycontactnumber': Utility.getCompanyMobileNumber,
      'template': 'WELCOMECUST',
      'filepath': this.junctionbox + document.documentpath,
      'templatetype': 'Email',
      'module': 'AUD',
    };

    this.http.post(Utility.serviceCoreURL + '/getemailtemplate', this.emailInfo).subscribe(data => {
      this.emailData = data.json();

      this.emailInfo = {
        'data': this.emailData['data'],
        'form': this.emailData['form'],
        'qualityscore':this.customerArg['qualityscore'],
        'deliverability':this.customerArg['deliverability'],
        'emailbody': this.emailData['emailbody'],
        'companyshortname': this.companyShortName,
        'loggingurl': Utility.serviceLoggingURL()
      };

      this.http.post(Utility.serviceIntegrationURL() + '/sendemail', this.emailInfo) .subscribe((res:Response) => {
        this.successEmailMessage = 'Email sent successfully!';
        this.sendMailStatus = false;
        this.errorEmailMessage = '';
        this.alertService.validationAlert();
        let emails = res.json();
        let email =emails['original'];
        let emailcounts=[email['toEmail']].length;

        // console.log(counts);
        this.updateBillingcounter( emailcounts);
      });
    });

  }
  updateBillingcounter(counts){
    this.billingcounter = {
      'templatetype': 'Email',
      'created_by':Utility.getLoggedUserId(),
      'executedate': this.currentDate,
      'count': counts,
    }
    this.http.post(Utility.serviceCoreURL + '/updatebillingcounter', this.billingcounter).subscribe(data => {
      data.json();
    });

  }
  sendSMS(document) {
    var time = new Date();
    if(time.toLocaleString('en-IN', { hour: 'numeric', hour12: false })< '22'&&
      time.toLocaleString('en-IN', { hour: 'numeric', hour12: false })> '09'){
    const headers = new HttpHeaders()
      .set('cache-control', 'no-cache')
      .set('content-type', 'application/json')
      .set('Authorization', 'aba7df0a852989a7acddd07bf12c23055334429e');
    const body = {
      long_url: this.junctionbox +'/' + encodeURIComponent(document.documentpath),
      domain: 'bit.ly'
    };
    this.httpClient.post(this.url, body, {headers: headers}).subscribe((res: Response) => {
      this.filelink = res['link'];
      this.logMessageParameter = {
        'message': 'Document sent to',
        'customer': this.customername,
        'datetime': this.currentDate,
        'communicationtype': 'SMS',
      };

      this.smsInfo = {
        'customername': this.customername,
        'mobilephone':this.customerArg['mobilephone'],
        'customer_id': this.customerArg['customer_id'],
        'documenttype': this.documents['documentname'],
        'companyname': Utility.getCompanyName,
        'companycontactnumber': Utility.getCompanyMobileNumber,
        'template': 'WELCOMECUST',
        'templatetype': 'SMS',
        'url': document!=''? this.filelink : '',
        'module': 'AUD',
      };

      this.http.post(Utility.serviceCoreURL + '/getesmstemplate', this.smsInfo).subscribe(data => {
        this.smsData = data.json();
        this.smsInfo = {
          'mobilenumber': this.smsData['mobileNumber'],
          'smsbody': this.smsData['smsbody'],
          'companyshortname': this.companyShortName,
          'loggingurl': Utility.serviceLoggingURL()
        };

        this.http.post(Utility.serviceIntegrationURL() + '/sendsms', this.smsInfo).subscribe(data => {
          this.successSmsMessage = 'SMS sent successfully!';
          this.sendSmsStatus = false;
          this.errorSmsMessage = '';
          this.alertService.validationAlert();

        });
      });
    });
    }else{
      alert('You Can Send SMS Between 9 AM to 9 PM Only');
    }
  }

  sendWhatsapp(document) {
    const headers = new HttpHeaders()
      .set('cache-control', 'no-cache')
      .set('content-type', 'application/json')
      .set('Authorization', 'aba7df0a852989a7acddd07bf12c23055334429e');
    const body = {
      long_url: this.junctionbox +'/' + encodeURIComponent(document.documentpath),
      domain: 'bit.ly'
    };
    this.httpClient.post(this.url, body, {headers: headers}).subscribe((res: Response) => {
      this.whatsappfileink = res['link'];
      this.logMessageParameter = {
        'message': 'Document sent to',
        'customer': this.customername,
        'datetime': this.currentDate,
        'communicationtype': 'WhatsApp',
      };

      this.whatsappInfo = {
        'customername': this.customername,
        'mobilephone':this.customerArg['mobilephone'],
        'customer_id': this.customerArg['customer_id'],
        'countryphonecode': this.customerArg['countryphonecode'],
        'documenttype': this.documents['documentname'],
        'companyname': Utility.getCompanyName,
        'companycontactnumber': Utility.getCompanyMobileNumber,
        'template': 'WELCOMECUST',
        'url': document.documentpath!=''? this.whatsappfileink : '',
        'templatetype': 'WhatsApp',
        'module': 'AUD',
      };

      this.http.post(Utility.serviceCoreURL + '/getewatsapptemplate', this.whatsappInfo).subscribe(data => {
        this.whatsappData = data.json();
        this.whatsappInfo = {
          'mobilenumber': this.whatsappData['mobileNumber'],
          'countryphonecode': this.whatsappData['countryphonecode'],
          'whatsappbody': this.whatsappData['whatsAppBody'],

          'companyshortname': this.companyShortName,
          'loggingurl': Utility.serviceLoggingURL()
        };
        if (!Utility.isUndefined(this.whatsappInfo['whatsappbody']) && !Utility.isNull(this.whatsappInfo['whatsappbody'])) {
          this.http.post(Utility.serviceIntegrationURL() + '/whatsappshare', this.whatsappInfo).subscribe(data => {
            // this.successWhatsappMessage = 'WHATSAPP sent successfully!';
            // this.sendWhatsappStatus = false;
            // this.errorWhatsappMessage = '';
            this.alertService.validationAlert();
            this.whatsAppUrl = data.json()['url'];
            window.open(this.whatsAppUrl, "_blank");

          });
        }
      });
    });
  }
    getRoute() {
        this.http.get(Utility.serviceCoreURL + '/getidbyarearoute?route=' + this.routes).subscribe((data) => {
            this.areaId = data.json();
        });
    }

    ngOnInit() {
      let yourDate = new Date('2022-07-20 06:54:00 UTC');
      yourDate.toString();
      console.log(yourDate.toString());
        $('.navbar-collapse li.active').removeClass('active');
        $('#menusales').addClass('active');
        this.rolename = localStorage.getItem('rolename');
        this.employeeId = localStorage.getItem('employeeid');
        this.route.params.subscribe((res) => {
            this.routes = this.router.url;
        });
        this.currentDate = Utility.getCurrentDateTime();
        this.getContact();
        this.getLookUp();
        this.getRoute();
        this.title.setTitle('Octanics - Contacts');
    }

}
