import { Component, OnInit } from '@angular/core';
import {Utility} from "@app/common/utility";
import {Http, Response} from '@angular/http';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {Lookup} from 'app/common/lookup';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Common} from "@app/common/common";
import {DatePipe} from "@angular/common";
import {HttpClient} from "@angular/common/http";

declare var $: any;

@Component({
  selector: 'app-twitter',
  templateUrl: './twitter.component.html',
  styleUrls: ['./twitter.component.css']
})
export class TwitterComponent implements OnInit {

    constructor(private http: HttpClient, private title: Title, private route: ActivatedRoute,
                private router: Router, private lookUp: Lookup, private datePipe: DatePipe) {
    }

  ngOnInit() {
      $('.sidenav li.active').removeClass('active');
      $('#menuSocialMedia').addClass('active');

      this.title.setTitle('Octanics - Twitter');
  }

}
