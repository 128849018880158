import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from 'app/common/utility';
import {Employee} from "@app/models/employee.model";
import {Title} from '@angular/platform-browser';
import * as CryptoJS from 'crypto-js';
import {Common} from 'app/common/common';

declare var $: any;

@Component({
    selector: 'app-employeeview',
    templateUrl: './employeeview.component.html',
    styleUrls: ['./employeeview.component.css']
})
export class EmployeeviewComponent implements OnInit {

    constructor(private http: Http, private router: Router, private route: ActivatedRoute, private title: Title) {
    }

    Common = Common;
    id: number;
    employeedetail: object = {};
    junctionbox = Utility.junctionboxPath();
  isBlinkable( deliverability) {
    if (deliverability !== '' && deliverability !== null) {
      if (deliverability !== 'DELIVERABLE') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
    ngOnInit() {
        $('.navbar-collapse li.active').removeClass('active');
        $('#menuHR').addClass('active');
        this.route.paramMap.subscribe(params => {
            this.id = Common.decryptId((params.get('id')));
        });
        this.http.get(Utility.serviceCoreURL + '/employeedetails?id=' + this.id).subscribe(data => {
            this.employeedetail = data.json();
        });

        this.title.setTitle('Octanics - Employee');
    }


}
