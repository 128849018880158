import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import {Utility} from 'app/common/utility';
import {promise} from "selenium-webdriver";
import {map} from "rxjs/operators";

@Injectable()
export class Lookup {
  constructor(private http: Http) {
  }

  getLookUp(value: string, param?: any, employeeId?: string) {
    switch (value) {
        // case 'communicationmodes':
        //     return this.http.get(Utility.serviceCoreURL + '/getlookup?lookuptype=communicationmodes').pipe(map(res => res.json()));

        case 'countries':
            return this.http.get(Utility.serviceCoreURL + '/getlookup?lookuptype=countries').pipe(map(res => res.json()));

        case 'states':
            return this.http.get(Utility.serviceCoreURL + '/getlookup?lookuptype=states').pipe(map(res => res.json()));

        case 'cities':
            return this.http.get(Utility.serviceCoreURL + '/getlookup?lookuptype=cities').pipe(map(res => res.json()));

        case 'customertypes':
            return this.http.get(Utility.serviceCoreURL + '/getlookup?lookuptype=customertypes').pipe(map(res => res.json()));

        case 'countrycode':
            return this.http.get(Utility.serviceCoreURL + '/getlookup?lookuptype=countrycode').pipe(map(res => res.json()));

        case 'getstatecountrywise':
            return this.http.get(Utility.serviceCoreURL + '/getlookup?lookuptype=getstatecountrywise').pipe(map(res => res.json()));

        case 'getcitystatewise':
            return this.http.get(Utility.serviceCoreURL + '/getlookup?lookuptype=getcitystatewise').pipe(map(res => res.json()));

        case 'exporttypes':
            return this.http.get(Utility.serviceCoreURL + '/getlookup?lookuptype=exporttypes&route=' + param).pipe(map(res => res.json()));

        case 'addresstypes':
            return this.http.get(Utility.serviceCoreURL + '/getlookup?lookuptype=addresstypes').pipe(map(res => res.json()));

        case 'documenttypes':
            return this.http.get(Utility.serviceCoreURL + '/getlookup?lookuptype=documenttypes').pipe(map(res => res.json()));

        case 'emailtemplates':
            return this.http.get(Utility.serviceCoreURL + '/getlookup?lookuptype=emailtemplates').pipe(map(res => res.json()));

        case 'smstemplates':
            return this.http.get(Utility.serviceCoreURL + '/getlookup?lookuptype=smstemplates').pipe(map(res => res.json()));

        case 'whatsapptemplates':
            return this.http.get(Utility.serviceCoreURL + '/getlookup?lookuptype=whatsapptemplates').pipe(map(res => res.json()));

        case 'templatetypes':
            return this.http.get(Utility.serviceCoreURL + '/getlookup?lookuptype=templatetypes').pipe(map(res => res.json()));

        case 'campaignstatus':
            return this.http.get(Utility.serviceCoreURL + '/getlookup?lookuptype=campaignstatus').pipe(map(res => res.json()));

        case 'frequency':
            return this.http.get(Utility.serviceCoreURL + '/getlookup?lookuptype=frequency').pipe(map(res => res.json()));
    }

  }
}
