import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from 'app/common/utility';
import {Employee} from 'app/models/employee.model';
import {FormGroup, Validators, FormControl} from '@angular/forms';
//import {ImageService} from '../../../image.service';
import {Lookup} from 'app/common/lookup';
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from '@angular/common/http';
import {Validate} from 'app/common/validate';
import {Title} from '@angular/platform-browser';
import {Common} from "@app/common/common";
import {AlertService} from "@app/common/alert.service";
import {isNullOrUndefined} from "util";
import {PopupService} from "@app/common/popup.service";
import {Emailverification} from "@app/common/emailverification";

declare var $: any;
const URL = './file.service';

class ImageSnippet {
    constructor(public src: string, public file: File) {
    }

    // file was object File
}

@Component({
  selector: 'app-updateemployee',
  templateUrl: './updateemployee.component.html',
  styleUrls: ['./updateemployee.component.css']
})
export class UpdateemployeeComponent implements OnInit {

    constructor(private http: Http, private title: Title,
                private router: Router, private route: ActivatedRoute, private lookUp: Lookup,
                private httpclient: HttpClient, private alertService: AlertService
                  ,private emailVerification: Emailverification) {
    }
    id: number;
    employeedetail: object = {};
    filetypeValidation = '';
    empform: FormGroup;
    employee: Employee;
    selectedFilePath = '';
    uploadingFilePercents = 0;
    uploadingFile = false;
    junctionBoxSubDomain = Utility.junctionBoxSubDomain();
    cities = [];
    states = [];
    countries = [];
    countryCode = [];
    junctionbox = Utility.junctionboxPath();
    stateId: number;
    Utility = Utility;
    companyShortName = Utility.getCompanyShortName();
    Validate = Validate;
    selectedProfilePictureFile: ImageSnippet;
    selectedProfilePictureFilePath: string;
    uploadingProfilePictureFilePercents = 0;
    uploadingProfilePictureFile = false;
  emailDetails=[];
  email : any;

    getLookUp() {
        this.lookUp.getLookUp('states').subscribe((data) => {
            if (data) {
                this.states = data;
            }
        });
        this.lookUp.getLookUp('cities').subscribe((data) => {
            if (data) {
                this.cities = data;
            }
        });
        this.lookUp.getLookUp('countries').subscribe((data) => {
            if (data) {
                this.countries = data;
            }
        });
        this.lookUp.getLookUp('countrycode').subscribe((data) => {
            if (data) {
                this.countryCode = data;
            }
        });
    }

    updateEmployee(employee) {
        if (this.empform.valid) {
            employee.id = this.id;
            employee.modified_by = Utility.getLoggedUserId();
            employee.business_id = localStorage.getItem('businessid');
            employee.dateofbirth = employee.dateofbirth === '' ? null : employee.dateofbirth;
            employee.joiningdate = employee.joiningdate === '' ? null : employee.joiningdate;

            employee.profilepicture = this.selectedProfilePictureFilePath ? this.selectedProfilePictureFilePath  : this.employeedetail['profilepicture'];
          if(employee.email!=this.email){
            this.emailVerification.emailVerified(employee.email)
              .subscribe(
                (jsonData) => {
                  this.emailDetails = jsonData;
                },
                (err) => {},
                () => {
                  employee.deliverability = this.emailDetails['deliverability'];
                  employee.qualityscore =  this.emailDetails['quality_score'];
                  this.http.post(Utility.serviceCoreURL + "/updateemployee", employee).subscribe((res: Response) => {
                    if (res) {
                      this.router.navigate(['/employees']);
                    }
                  });

                });
          }else{
            employee.deliverability = this.employee['deliverability'];
            employee.qualityscore = this.employee['qualityscore'];
            this.http.post(Utility.serviceCoreURL + "/updateemployee", employee).subscribe((res: Response) => {
              if (res) {
                this.router.navigate(['/employees']);
              }
            });
          }
            // this.http.post(Utility.serviceCoreURL + '/updateemployee', employee).subscribe((res: Response) => {
            //     this.router.navigate(['/employees']);
            // });
        } else {
            this.alertService.validationAlert();
        }
    }

    deleteemployee = function(id) {
        if (confirm("Are you sure you want to delete this record?")) {
            this.employee = {
                "id": id,
                "deleted_by": Utility.getLoggedUserId(),
            };
            this.http.post(Utility.serviceCoreURL + "/deleteemployee", this.employee).subscribe((res: Response) => {
                this.router.navigate(['/employees']);
            });
        }
    };

    uploadProfilePicture(event) {
        if (event.target.files.length > 0) {
            let x = event.target.files[0].name.split('.');
            let extension = x[1];

            if (Utility.isOfImageType(extension)) {
                this.filetypeValidation = '';
                if (event.target.files) {
                    for (let i = 0; i < event.target.files.length; i++) {
                        let reader = new FileReader();
                        reader.readAsDataURL(event.target.files[i]);
                        reader.onload = (events: any) => {
                            this.selectedProfilePictureFile = new ImageSnippet(events.target.result[i], event.target.files[i]);
                            this.uploadDocument(this.selectedProfilePictureFile.file);
                        };
                    }
                }
            } else {
                this.filetypeValidation = 'Selected file type must be .png, .jpeg, .jpg';
                this.alertService.validationAlert();
            }
        } else {
            this.filetypeValidation = '';
        }
    }

    public uploadDocument(profilepicture: any) {
        const formData = new FormData();
        formData.append('file', profilepicture);
        formData.append('filename', profilepicture.name);
        formData.append('filepath', this.companyShortName.replace(/\s/g, '').toLowerCase() + '/assets/profilepicture');
        formData.append('subdomain', this.junctionBoxSubDomain);

        let req = new HttpRequest('POST', Utility.serviceStorageURL() + '/uploadfile', formData, {
            reportProgress: true
        });

        this.httpclient.request(req).subscribe(event => {
            this.selectedProfilePictureFilePath = event['body'];
            // Via this API, you get access to the raw event stream.
            if (event.type === HttpEventType.UploadProgress) {
                // This is an upload progress event. Compute and show the % done:
                this.uploadingProfilePictureFilePercents = Math.round(100 * event.loaded / event.total);
                this.uploadingProfilePictureFile = true;
                // Look for upload progress events.
            } else if (event instanceof HttpResponse) {
                this.uploadingProfilePictureFile = false;
            }
        });
    }

    ngOnInit() {
        $('.navbar-collapse li.active').removeClass('active');
        $('#menuHR').addClass('active');

        let emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";

        this.getLookUp();

        this.empform = new FormGroup({
            title: new FormControl('', null),
            firstname: new FormControl('', Validators.required),
            lastname: new FormControl('', Validators.required),
            middlename: new FormControl('', null),
            gender: new FormControl('', null),
            email: new FormControl('', Validators.pattern(emailPattern)),
            countryphonecode: new FormControl('', null),
            contactno: new FormControl('', null),
            address1: new FormControl('', Validators.required),
            address2: new FormControl('', null),
            city_id: new FormControl('', Validators.required),
            state_id: new FormControl('', Validators.required),
            country_id: new FormControl('', Validators.required),
            pincode: new FormControl('', Validators.required),
            dateofbirth: new FormControl(null),
            joiningdate: new FormControl(null),
            profilepicture: new FormControl(null)
        });

        this.route.paramMap.subscribe(params => {
            this.id =  Common.decryptId((params.get('id')));
        });
        this.http.get(Utility.serviceCoreURL + "/employeedetails?id=" + this.id).subscribe(data => {
            this.employeedetail = data.json();
            this.selectedProfilePictureFilePath = this.employeedetail['profilepicture'];
        });

        this.title.setTitle('Octanics - Employee');

        if (this.junctionBoxSubDomain == 'null') {
            this.junctionBoxSubDomain = null;
        }
    }
}
