import { Component, OnInit } from '@angular/core';
import {Http, Response} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Lookup} from "@app/common/lookup";
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Utility} from 'app/common/utility';
import {Common} from "@app/common/common";
import {AlertService} from 'app/common/alert.service';

declare var $: any;


@Component({
  selector: 'app-updatetemplate',
  templateUrl: './updatetemplate.component.html',
  styleUrls: ['./updatetemplate.component.css']
})
export class UpdatetemplateComponent implements OnInit {

    constructor(private http: Http,
                private router: Router,
                private route: ActivatedRoute,
                private title: Title,
                private lookUp: Lookup, private alertService: AlertService) { }

    public options: Object = {
        imageUploadURL: Utility.serviceStorageURL() + '/uploadeditorimage',
        // imageUploadURL: 'assets/images/'
        //   toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL',  'outdent', 'indent', '-', 'insertImage', 'embedly',
        //     'insertTable', 'insertLink'],
        //   toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat','align', 'formatOL',  'outdent', 'indent', '-', 'insertImage', 'embedly',
        //     'insertTable', 'insertLink' ],
        //   toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL',  'outdent', 'indent', '-', 'insertImage', 'embedly',
        //     'insertTable', 'insertLink'],
        //   toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL',  'outdent', 'indent', '-', 'insertImage', 'embedly',
        //     'insertTable', 'insertLink'],
    };

    id: number;
    emailvalue: string;
    emailtext;
    communicationmodeId: any;
    communicationmodes = [];
    templates= [];
    templateDetails = [];
    templateForm: FormGroup;

    getLookUp () {
        this.lookUp.getLookUp('communicationmodes').subscribe((data) => {if (data) {this.communicationmodes = data; }});
        this.lookUp.getLookUp('templates').subscribe((data) => {if (data) {this.templates = data; }});
    }

    selectCommunicationmodeId(args) {
        this.communicationmodeId = args.target.value;
        var selectedCommunicationmode = $('select.communicationmode option:selected').text();
        if (selectedCommunicationmode == 'null') {
            $('#emaildesc').show();
            $('#emaildesc').val('');
            $('#subjectdiv').show();
            $('#subjectdiv').val('');
            $('#blankdiv').show();
            $('#smsdesc').hide();
            $('#smsdesc').val('');
        } else {
            if ((selectedCommunicationmode.trim() == 'SMS' )) {
                $('#emaildesc').hide();
                $('#emaildesc').val(null);
                $('#subjectdiv').hide();
                $('#subjectdiv').val(null);
                $('#blankdiv').hide();
                $('#smsdesc').show();
                $('#smsdesc').val(null);
            } else {
                $('#emaildesc').show();
                $('#emaildesc').val('');
                $('#subjectdiv').show();
                $('#subjectdiv').val('');
                $('#blankdiv').show();
                $('#smsdesc').hide();
                $('#smsdesc').val('');
            }
        }
    }

    onChange($event) {
        this.emailtext = $event;
    }

    updateTemplate(data) {
        if (this.templateForm.valid) {
            data.modified_by = Utility.getLoggedUserId();
            data.id = this.id;
            this.http.post(Utility.serviceCoreURL + '/updatetemplate', data).subscribe((res: Response) => {
                $('#frmtemplate').get(0).reset();
                this.router.navigate(['templates']);
            });
        }
        else{
            this.alertService.validationAlert();
        }
    }

    deleteTemplate(id) {
        if (confirm('Are you sure you want to delete this record?')) {
            const deletetemplate = {
                'id': id,
                'deleted_by': Utility.getLoggedUserId(),
            };
            this.http.post(Utility.serviceCoreURL + '/deletetemplate', deletetemplate).subscribe((res: Response) => {
                this.router.navigate(['/templates']);
            });
        }
    }

    ngOnInit() {

        $('.navbar-collapse li.active').removeClass('active');
        $('#menudatamanagement').addClass('active');

        this.title.setTitle('Octanics - Template');

        $('#editor').froalaEditor({key: Utility.editorLicenseKey});

        this.route.paramMap.subscribe(params => {
            this.id =  Common.decryptId((params.get('id')));
        });

        this.getLookUp();
        this.emailvalue = localStorage.getItem('email');

        this.http.get(Utility.serviceCoreURL + '/templatedetail?id= ' + this.id).subscribe(data => {
            this.templateDetails = data.json();

            if (this.templateDetails['communicationmode'] == 'SMS') {
                $('#emaildesc').hide();
                $('#subjectdiv').hide();
                $('#blankdiv').hide();
                $('#smsdesc').show();
            } else {
                $('#emaildesc').show();
                $('#subjectdiv').show();
                $('#blankdiv').show();
                $('#smsdesc').hide();
            }
        });

        this.templateForm = new FormGroup({
            templatetype_id: new FormControl('', Validators.required),
            communicationmode_id: new FormControl('', null),
            subject: new FormControl('', null),
            description: new FormControl('', null),
        });
    }


}
