import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Http} from '@angular/http';
import {Common} from 'app/common/common';
import {Utility} from 'app/common/utility';
import {Sorting} from "@app/common/sorting";

declare var $: any;

@Component({
  selector: 'app-whatsapplist',
  templateUrl: './whatsapplist.component.html',
  styleUrls: ['./whatsapplist.component.css']
})
export class WhatsapplistComponent implements OnInit {

    constructor(private title: Title, private http: Http) { }

    utility = Utility;
    Sorting = Sorting;
    searchText;
    p: Number = 1;
    whatsapptemplates = [];
    Common = Common;
    order: boolean = true;
    property: string = null;

    sorting(property, obj) {
        if (property == this.property) {
            this.property = property;
            this.order = !this.order;
            if (this.order) {
                $('.table-borderless thead th i').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
                $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-up').css('color', '1E2859');
            } else {
                $('.table-borderless thead th i').removeClass('fad fa-sort-up').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
                $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-down').css('color', '1E2859');
            }
            Sorting.sort(property, obj, this.order);
        } else {
            this.property = property;
            this.order = false;
            $('.table-borderless thead th i').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
            $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-down').css('color', '1E2859');
            Sorting.sort(property, obj, this.order);
        }
    }

    getList = function() {
        this.http.get(Utility.serviceCoreURL + '/whatsapptemplates').subscribe(data => {
            this.whatsapptemplates = data.json();
        });
    };
    ngOnInit() {
        $('.navbar-collapse li.active').removeClass('active');
        $('#menudatamanagement').addClass('active');

        this.getList();
        this.title.setTitle('Octanics - WhatsApp Templates');
    }


}
