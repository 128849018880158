import { Component, OnInit } from '@angular/core';
import {Http, Response} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Lookup} from "@app/common/lookup";
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Utility} from 'app/common/utility';
import {AlertService} from 'app/common/alert.service';

declare var $: any;

@Component({
  selector: 'app-inserttemplate',
  templateUrl: './inserttemplate.component.html',
  styleUrls: ['./inserttemplate.component.css']
})
export class InserttemplateComponent implements OnInit {

    constructor(private http: Http, private router: Router,
                private title: Title, private route: ActivatedRoute, private lookUp: Lookup, private alertService: AlertService) { }

    templateEmailDetail: object = [];

    public options: Object = {
        imageUploadURL: Utility.serviceStorageURL() + '/uploadeditorimage',
        /*toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
          'insertTable', 'insertLink'],
        toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
          'insertTable', 'insertLink'],
        toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
          'insertTable', 'insertLink'],
        toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
          'insertTable', 'insertLink'],*/
    };
    templateSmsDetail: object = [];
    email;
    emailtext;
    value: number;
    communicationmodes = [];
    templates = [];
    Utility = Utility;
    templateForm: FormGroup;
    communicationmodeId: any;
    communicationmodeName: string;
    emailvalue: string;

    getLookUp () {
        this.lookUp.getLookUp('communicationmodes').subscribe((data) => {if (data) {this.communicationmodes = data;}});
        this.lookUp.getLookUp('templates').subscribe((data) => {if (data) {this.templates = data;}});
    }

    selectCommunicationModeId(args) {
        this.communicationmodeId = args.target.value;

        if (this.communicationmodeId == 'null') {
            $('#emaildesc').show();
            $('#subjectdiv').show();
            $('#blankdiv').show();
            $('#smsdesc').hide();
        } else {
            this.http.get(Utility.serviceCoreURL + '/communicationmodename?id=' + this.communicationmodeId).subscribe(data => {
                this.communicationmodeName = data.json();

                if (this.communicationmodeName == 'SMS') {
                    $('#emaildesc').hide();
                    $('#subjectdiv').hide();
                    $('#blankdiv').hide();
                    $('#smsdesc').show();
                } else {
                    $('#emaildesc').show();
                    $('#subjectdiv').show();
                    $('#blankdiv').show();
                    $('#smsdesc').hide();
                }
            });
        }
    }

    onChange($event) {
        this.emailtext = $event;
    }

    insertTemplate (data) {
        if (this.templateForm.valid) {
            if (this.emailtext != undefined) {
                data.description = this.emailtext;
            }
            data.modified_by = Utility.getLoggedUserId();
            this.http.post(Utility.serviceCoreURL + '/inserttemplate', data).subscribe((res: Response) => {
                $('#frmtemplate').get(0).reset();
                this.router.navigate(['templates']);
            });
        } else{
            this.alertService.validationAlert();
        }
    }

    ngOnInit() {

        $('.navbar-collapse li.active').removeClass('active');
        $('#menudatamanagement').addClass('active');

        this.getLookUp();

        this.emailvalue = localStorage.getItem('email');

        this.title.setTitle('Octanics - Template');

        $('#editor').froalaEditor({key: Utility.editorLicenseKey});

        this.templateForm = new FormGroup({
            templatetype_id: new FormControl('', Validators.required),
            communicationmode_id: new FormControl('', null),
            subject: new FormControl('', null),
            description: new FormControl('', null),
        });
    }


}
