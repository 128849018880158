import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {Title} from '@angular/platform-browser';
import {DatePipe} from '@angular/common';
import {Utility} from 'app/common/utility';
import {Common} from 'app/common/common';
import {Documenttype} from "@app/models/documenttype.model";
import {Sorting} from "@app/common/sorting";

declare var $: any;

@Component({
  selector: 'app-documenttypelist',
  templateUrl: './documenttypelist.component.html',
  styleUrls: ['./documenttypelist.component.css']
})
export class DocumenttypelistComponent implements OnInit {

    constructor(private http: Http, private title: Title, private datePipe: DatePipe) {
    }

    searchText;
    documenttype: Documenttype;
    Common = Common;
    documenttypes = [];
    p: Number = 1;
    count: Number = 20;
    Sorting = Sorting;
    order: boolean = true;
    property: string = null;


    sorting(property, obj) {
        if (property == this.property) {
            this.property = property;
            this.order = !this.order;
            if (this.order) {
                $('.table-borderless thead th i').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
                $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-up').css('color', '1E2859');
            } else {
                $('.table-borderless thead th i').removeClass('fad fa-sort-up').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
                $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-down').css('color', '1E2859');
            }
            Sorting.sort(property, obj, this.order);
        } else {
            this.property = property;
            this.order = false;
            $('.table-borderless thead th i').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
            $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-down').css('color', '1E2859');
            Sorting.sort(property, obj, this.order);
        }
    }

    getList = function () {
        this.http.get(Utility.serviceCoreURL + "/documenttypes").subscribe(data => {
            this.documenttypes = data.json();
        });
    };


    ngOnInit() {
        $('.navbar-collapse li.active').removeClass('active');
        $('#menumaster').addClass('active');
        this.getList();
        this.title.setTitle('Octanics - Document Types');
    }


}
