import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from 'app/common/utility';
import {Title} from '@angular/platform-browser';
import {Common} from 'app/common/common';
import {DatePipe} from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-contactview',
  templateUrl: './contactview.component.html',
  styleUrls: ['./contactview.component.css']
})
export class ContactviewComponent implements OnInit {

    constructor(private http: Http, private router: Router, private datePipe: DatePipe, private route: ActivatedRoute, private title: Title) {
    }

    Common = Common;
    id: number;
    contactdetails: object = {};
    p: Number = 1;
    count: Number = 5;
    mobile: string;
    businessphone: string;
    homephone: string;
    website: string;
    tags: any;

    goToURLPhone() {
        // location.href = "https://"+this.website , '_blank';
        window.location.href = 'tel:' + this.mobile;


    }

    goToURLBusinessphone() {
        // location.href = "https://"+this.website , '_blank';
        window.location.href = 'tel:' + this.businessphone;


    }

    goToURLHomePhone() {
        // location.href = "https://"+this.website , '_blank';
        window.location.href = 'tel:' + this.homephone;


    }
    goToURL() {
        // location.href = "https://"+this.website , '_blank';
        window.open("https://" + this.website, '_blank');
    }
    isBlinkable( deliverability) {
        if (deliverability !== '' && deliverability !== null) {
            if (deliverability !== 'DELIVERABLE') {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    ngOnInit() {
        $('.navbar-collapse li.active').removeClass('active');
        $('#menusales').addClass('active');
        $('.historyBoxItemContainer').on('click', function (e) {
            e.stopPropagation();
        });
        this.route.paramMap.subscribe(params => {
            this.id = Common.decryptId((params.get('id')));
        });

        this.http.get(Utility.serviceCoreURL + '/customerdetails?id=' + this.id).subscribe(data => {
            this.contactdetails = data.json();
          // console.log(this.contactdetails);
            this.tags = this.contactdetails['tags'];
            $('#tags').val(this.tags);
            $('#tags').selectize({
            create: false,
             onDelete: function(values) {
              return false;
            }
          });
            this.mobile = data['mobilephone'];
            this.businessphone = data['businessphone'];
            this.homephone = data['homephone'];
        });

        this.title.setTitle('Octanics - Contact');

    }

}
