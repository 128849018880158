import {Component, OnInit} from '@angular/core';
import {Utility} from "@app/common/utility";
import {Http, Response} from '@angular/http';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {Lookup} from 'app/common/lookup';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Common} from "@app/common/common";
import {DatePipe} from "@angular/common";
import {HttpClient} from "@angular/common/http";

declare var $: any;

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.css']
})
export class WhatsappComponent implements OnInit {

    constructor(private http: HttpClient, private title: Title, private route: ActivatedRoute,
                private router: Router, private lookUp: Lookup, private datePipe: DatePipe) {
    }
    selected = "all";
    communicationmodes = [];
    customerTypes = [];
    CommunicationForm: FormGroup;
    errorSmsMessage = '';
    id: number;
    smsId: number;
    checked: any;
    successSmsMessage = '';
    successEmailMessage = '';
    contacts :any;
    checkedContacts = [];
    p: Number = 1;
    count: Number = 20;
    Common = Common;
    includes;
    business_id: any = localStorage.getItem('businessid');
    noDataMessage;
    whatsappcontactscount;
    contactList=[];

    deletecustomer = function (id) {
        if (confirm("Are you sure you want to delete this record?")) {
            this.contacts = {
                "id": id,
                "deleted_by": Utility.getLoggedUserId(),
            };
            this.http.post(Utility.serviceCoreURL + "/deletecustomer", this.contacts).subscribe((res: Response) => {
                this.searchWhatsApp(this.contacts);
                //this.router.navigate(['/whatsapp']);
            });
        }
    };

    getLookUp() {
        this.lookUp.getLookUp('templatetypes').subscribe((data) => {
            if (data) {
                this.communicationmodes = data;
                let type = this.communicationmodes.find(x => x.name == 'SMS');
                this.smsId = type['id'];
            }
        });
        this.lookUp.getLookUp('customertypes').subscribe((data) => {
            if (data) {
                this.customerTypes = data;
            }
        });
    }

    allTrades(event, contacts) {
        const checked = event.target.checked;
        this.contacts.forEach(item => item.selected = checked);
        this.checkedContacts.push(contacts);
    }

    onCheck(evt) {
        if (!this.checkedContacts.includes(evt)) {
            this.checkedContacts.push(evt);
        } else {
            var index = this.checkedContacts.indexOf(evt);
            if (index > -1) {
                this.checkedContacts.splice(index, 1);
            }
        }
    }

  redirectCampaign(contact): void {
    contact.forEach((element) => {
      this.contactList.push(element.id);
    });
    // Create our query parameters object
    const queryParams: any = {};
    // Create our array of values we want to pass as a query parameter
    const arrayOfValues = Common.encryptId(this.contactList);
    // Add the array of values to the query parameter as a JSON string
    queryParams.id = arrayOfValues;
    // Create our 'NaviationExtras' object which is expected by the Angular Router
    const navigationExtras: NavigationExtras = {
      queryParams
    };
    this.router.navigate(['/insertwhatsappcampaign'], navigationExtras);
  }

    searchWhatsApp(whatsapp) {
            whatsapp.customertype_id = whatsapp.customertype_id !== undefined ? whatsapp.customertype_id : null;
            whatsapp.tags = $('#tags').val();
            this.http.get(Utility.serviceCoreURL + '/whatsappcontacts?customertype_id=' + whatsapp.customertype_id + '&tags=' + whatsapp.tags + '&business_id=' + this.business_id).subscribe(data => {
                this.contacts = data;
                this.whatsappcontactscount = this.contacts.length;
                this.noDataMessage = !this.contacts.length;
            });
    }
    ngOnInit() {

        $('.sidenav li.active').removeClass('active');
        $('#menuMarketing').addClass('active');
        $('#tags').selectize({
            delimiter: ',',
            persist: false,
            create:function(input) {
                return {
                    value: input,
                    text: input
                };
            }
        });
        //this.getList();
        this.getLookUp();
        this.title.setTitle('Octanics - WhatsApp');
    }


}
