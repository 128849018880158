import { Component, OnInit } from '@angular/core';
import {TokenService} from '../common/token.service';
import {Utility} from '../common/utility';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent implements OnInit {

    constructor(private router: Router, private http: HttpClient, private Token: TokenService, private title: Title) {
    }

    ngOnInit() {
      this.title.setTitle('Octanics');
      let email = localStorage.getItem('email');
      let token = localStorage.getItem('token');
      this.http.get(Utility.serviceAuthorisationURL + '/checktoken?email=' + email + '&token=' + token + '&url=' + Utility.serviceCoreURL).subscribe((res) => {
        if (res !== '' && res !== null && res !== undefined) {
          res = res;
          if (res['success'] == 'true') {
            this.Token.checkTokenAtAuthenticate();
              this.router.navigate(['/dashboard']);
              return true;
          } else {
            this.router.navigate(['/login']);
            return false;
          }
        }else{
          this.router.navigate(['/login']);
          return false;
        }
      });
    }

}
