import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from 'app/common/utility';
// import {Configuration} from 'assets/general/configuration';
import {FormBuilder, FormGroup, Validators, FormControl, ValidatorFn, AbstractControl} from '@angular/forms';
import * as bcrypt from 'bcryptjs';
import {Title} from '@angular/platform-browser';
import {Lookup} from '../common/lookup';
import {AlertService} from '../common/alert.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
  constructor(private http: Http, private router: Router, private route: ActivatedRoute, private title: Title, private alertService: AlertService) {
  }
  changePasswordForm: FormGroup;
  errorMessage = '';
  password: object = [];
  id: number;
  UserId: string;
  length: number;
  confimpassword='';
  updatepassword = function (changepassword) {
    if (this.changePasswordForm.valid) {
      this.http.get(Utility.serviceCoreURL + '/getuserbyid?id=' +  this.UserId).subscribe(data => {
        const auth = data.json();
        this.status = bcrypt.compareSync(changepassword.password, auth['password']);
        if (this.status && (changepassword.newpassword === changepassword.confirmpassword) && (changepassword.newpassword !== '') && (changepassword.confirmpassword !== '')) {
          const salt = bcrypt.genSaltSync(10);
          changepassword.newpassword = bcrypt.hashSync(changepassword.newpassword, salt);
          this.newpassword = {
            'id': this.UserId,
            'newpassword': changepassword.newpassword
          };
          this.http.post(Utility.serviceCoreURL + '/updatepassword', this.newpassword).subscribe(data2 => {
            if (data2['_body'] === 'Some error occurred') {
              this.errorMessage = 'Error!';
              this.alertService.validationAlert();
            } else {
              localStorage.removeItem('userid');
              localStorage.removeItem('email');
              // localStorage.removeItem('username');
              localStorage.removeItem('firstname');
              localStorage.removeItem('lastname');
              localStorage.removeItem('rolename');
              localStorage.removeItem('employeeid');
              localStorage.removeItem('profilephoto');
              localStorage.removeItem('aliasrolename');
              sessionStorage.removeItem('eid');
              this.router.navigate(['/']);
            }
          });
        } else {
          if (changepassword.password.length) {
            this.errorMessage = "Old password does not match";
            this.alertService.validationAlert();
          }
        }
      });
    }else{
      this.alertService.validationAlert();
    }
  };


  /* updatepassword = function (changepassword) {
       changepassword.id = this.UserId;
       changepassword.password = changepassword.newpassword;
       this.http.get(Configuration.serviceCoreURL + "/getpassword?id=" + this.UserId + "&password=" + this.password).subscribe(data => {
           if (data['_body'] === '') {
               this.errorMessage = 'Old Password Not Match';
           } else {
               this.http.post(Configuration.serviceCoreURL + "/updatepassword", changepassword).subscribe((res: Response) => {
                   this.router.navigate(['/changepassword']);
               });
           }
       });
   };*/

  passConfirm() {
    if (this.changePasswordForm.get('newpassword').value !== this.changePasswordForm.get('confirmpassword').value) {
      this.confimpassword = 'Password is not same';
      this.alertService.validationAlert();
    } else {
      this.confimpassword = '';
    }
  }


  inputChanged(event: any) {
    this.password = event.target.value;

  }

  ngOnInit() {

    this.title.setTitle('Octanics - Change Password');
    this.UserId = localStorage.getItem('userid');

    this.changePasswordForm = new FormGroup({
      password: new FormControl('', [Validators.required]),
      newpassword: new FormControl('', Validators.required),
      confirmpassword: new FormControl('', [Validators.required])
    });

    // function passwordMatchValidator(password: string): ValidatorFn {
    //     //
    //     // return (control: FormControl) => {
    //     //     if (!control || !control.parent) {
    //     //         return null;
    //     //     }
    //     //     //this.confimpassword= 'Password is not same';
    //     //     let password= control.parent.get('newpassword').value === control.value ? null : {mismatch:true};
    //     //     // console.log(password);
    //     //     if(password==true){
    //     //
    //     //     }else{
    //     //       console.log('else');
    //     //     }
    //     // };
    // }
  }


}
