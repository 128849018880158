import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers, ResponseContentType} from '@angular/http';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {Utility} from 'app/common/utility';
import {Common} from 'app/common/common';
import {ActivatedRoute, Router} from '@angular/router';
import {Sorting} from 'app/common/sorting';
import {Lookup} from "@app/common/lookup";
import {HttpClient, HttpRequest} from "@angular/common/http";
import {AlertService} from "@app/common/alert.service";
import {Employee} from "@app/models/employee.model";

declare var $: any;

@Component({
  selector: 'app-employeelist',
  templateUrl: './employeelist.component.html',
  styleUrls: ['./employeelist.component.css']
})
export class EmployeelistComponent implements OnInit {

    constructor(private http: Http, private title: Title,
                private router: Router, private route: ActivatedRoute, private lookUp: Lookup,
                private httpclient: HttpClient, private alertService: AlertService) {
    }

    utility = Utility;
    Sorting = Sorting;
    Common = Common;
    p: Number = 1;
    count: Number = 20;
    search;
    employee: Employee;
    employees = [];
    order: boolean = true;
    property: string = null;
    business_id = localStorage.getItem('businessid');
    junctionbox = Utility.junctionboxPath();
    junctionBoxSubDomain = Utility.junctionBoxSubDomain();
    companyShortName = Utility.getCompanyShortName();
    id: number;

    sorting(property, obj) {
        if (property == this.property) {
            this.property = property;
            this.order = !this.order;
            if (this.order) {
                $('.table-borderless thead th i').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
                $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-up').css('color', '1E2859');
            } else {
                $('.table-borderless thead th i').removeClass('fad fa-sort-up').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
                $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-down').css('color', '1E2859');
            }
            Sorting.sort(property, obj, this.order);
        } else {
            this.property = property;
            this.order = false;
            $('.table-borderless thead th i').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
            $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-down').css('color', '1E2859');
            Sorting.sort(property, obj, this.order);
        }
    }

    getEmployee() {
        this.http.get(Utility.serviceCoreURL + '/employees?business_id=' + this.business_id).subscribe(data => {
            this.employees = data.json();
        });
    }


    ngOnInit() {
        $('.navbar-collapse li.active').removeClass('active');
        $('#menusales').addClass('active');

        this.getEmployee();

        this.title.setTitle('Octanics - Employees');
    }

}
