import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-turbobusinesscustomer',
  templateUrl: './turbobusinesscustomer.component.html',
  styleUrls: ['./turbobusinesscustomer.component.css']
})
export class TurbobusinesscustomerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
