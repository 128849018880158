import { Component, OnInit } from '@angular/core';
import {Http, Response} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Lookup} from "@app/common/lookup";
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Utility} from 'app/common/utility';
import {AlertService} from 'app/common/alert.service';
import {Common} from "@app/common/common";
import {HttpClient,HttpEventType, HttpRequest, HttpResponse} from "@angular/common/http";
class ImageSnippet {
  constructor(public src: string, public file: File) {
  }
}
declare var $: any;

@Component({
  selector: 'app-updateemail',
  templateUrl: './updateemail.component.html',
  styleUrls: ['./updateemail.component.css']
})
export class UpdateemailComponent implements OnInit {

    constructor(private http: Http, private router: Router,
                private httpclient: HttpClient,
                private title: Title, private route: ActivatedRoute, private lookUp: Lookup, private alertService: AlertService) { }

    public options: Object = {
        imageUploadURL: Utility.serviceStorageURL() + '/uploadeditorimage',
        /*toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
          'insertTable', 'insertLink'],
        toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
          'insertTable', 'insertLink'],
        toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
          'insertTable', 'insertLink'],
        toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
          'insertTable', 'insertLink'],*/
    };
    public footeroptions: Object = {
        imageUploadURL: Utility.serviceStorageURL() + '/uploadeditorimage',
        /*toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
          'insertTable', 'insertLink'],
        toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
          'insertTable', 'insertLink'],
        toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
          'insertTable', 'insertLink'],
        toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
          'insertTable', 'insertLink'],*/
    };
    id: number;
    email;
    emailtext;
    emailfootertext;
    value: number;
    templatetypes = [];
    templates = [];
    Utility = Utility;
    emailtemplateForm: FormGroup;
    emailvalue: string;
    emailtemplateDetails = [];
    emailId;
  documentSizeLimit = localStorage.getItem('docsizelimit');
  documentFileSizeLimit = this.formatBytes(this.documentSizeLimit);
  documentValidationMessage: string = '';
  selectedFileOriginalName: string;
  selectedDocumentFile: ImageSnippet;
  selectedDocumentFilePath: string = null;
  documentsValidation: boolean;
  companyShortName = Utility.getCompanyShortName();
  junctionbox = Utility.junctionboxPath();
  junctionBoxSubDomain = Utility.junctionBoxSubDomain();
  uploadingDocumentPercents = 0;
  uploadingFile = false;

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

    getLookUp() {
        this.lookUp.getLookUp('templatetypes').subscribe((data) => {
            if (data) {this.templatetypes = data;
                let type = this.templatetypes.find(x => x.name == 'Email');
                this.emailId = type['id'];
            }});
    }
  uploadDocument(event) {

    if (event.target.files.length > 0) {

      if (event.target.files[0].size <= this.documentSizeLimit) {
        this.documentValidationMessage = '';
        if (event.target.files) {
          for (let i = 0; i < event.target.files.length; i++) {
            let reader = new FileReader();
            reader.readAsDataURL(event.target.files[i]);
            reader.onload = (events: any) => {
              this.selectedFileOriginalName = event.target.files[i].name;
              this.selectedDocumentFile = new ImageSnippet(events.target.result[i], event.target.files[i]);

              this.uploadDocuments(this.selectedDocumentFile.file);

            };
          }
        }

      }

      else {
        this.selectedDocumentFilePath = null;
        this.documentsValidation = true;
        this.selectedFileOriginalName = null;
        this.documentValidationMessage = 'Please upload document as per given size';
        this.alertService.validationAlert();
      }
    } else {
      this.selectedDocumentFilePath = null;
      this.selectedFileOriginalName = null;
      this.documentsValidation = true;
    }
  }
  public uploadDocuments(document: any) {
    const formData = new FormData();
    formData.append('file', document);
    formData.append('filename', document.name);
    formData.append('filepath', this.companyShortName.replace(/\s/g, '').toLowerCase() + '/assets/businessdocuments');
    formData.append('subdomain', this.junctionBoxSubDomain);

    let req = new HttpRequest('POST', Utility.serviceStorageURL() + '/uploadfile', formData, {
      reportProgress: true
    });
    this.httpclient.request(req).subscribe(event => {
      this.selectedDocumentFilePath = event['body'];
      // Via this API, you get access to the raw event stream.
      if (event.type === HttpEventType.UploadProgress) {
        // This is an upload progress event. Compute and show the % done:
        this.uploadingDocumentPercents = Math.round(100 * event.loaded / event.total);
        this.uploadingFile = true;
        // Look for upload progress events.
      } else if (event instanceof HttpResponse) {
        this.uploadingFile = false;
      }
    });

  }

    onChange($event) {
        this.emailtext = $event;
    }

    onFooterChange($event) {
        this.emailfootertext = $event;
    }

    updateTemplate(data) {
        if (this.emailtemplateForm.valid) {
          data.documentpath = this.selectedDocumentFilePath;
            data.modified_by = Utility.getLoggedUserId();
            data.id = this.id;
            this.http.post(Utility.serviceCoreURL + '/updateemailtemplate', data).subscribe((res: Response) => {
                $('#frmtemplate').get(0).reset();
                this.router.navigate(['emailtemplates']);
            });
        }
        else{
            this.alertService.validationAlert();
        }
    }

    deleteTemplate(id) {
        if (confirm('Are you sure you want to delete this record?')) {
            const deletetemplate = {
                'id': id,
                'deleted_by': Utility.getLoggedUserId(),
            };
            this.http.post(Utility.serviceCoreURL + '/deleteemailtemplate', deletetemplate).subscribe((res: Response) => {
                this.router.navigate(['/emailtemplates']);
            });
        }
    }


    ngOnInit() {

        $('.navbar-collapse li.active').removeClass('active');
        $('#menudatamanagement').addClass('active');


        this.getLookUp();

        this.route.paramMap.subscribe(params => {
            this.id =  Common.decryptId((params.get('id')));
        });

        this.emailvalue = localStorage.getItem('email');

        this.title.setTitle('Octanics - Email Template');

        // $('#editor').froalaEditor({key: Utility.editorLicenseKey});

        $('#editor').froalaEditor({key: Utility.editorLicenseKey , height: 200,   placeholderText: "<br/>"});
        $('#footereditor').froalaEditor({key: Utility.editorLicenseKey , height: 200,   placeholderText: "<br/>"});

        this.http.get(Utility.serviceCoreURL + '/emailtemplatedetail?id=' + this.id).subscribe(data => {
            this.emailtemplateDetails = data.json();
          this.selectedDocumentFilePath=this.emailtemplateDetails['documentpath'];

        });

        this.emailtemplateForm = new FormGroup({
            // templatetype_id: new FormControl('', null),
            templatename: new FormControl('', Validators.required),
            subject: new FormControl('', null),
            description: new FormControl('', null),

            footer: new FormControl('', null)
        });

      if (this.junctionBoxSubDomain == 'null') {
        this.junctionBoxSubDomain = null;
      }
    }

}
