import {Component, OnInit} from '@angular/core';
import * as XLSX from 'xlsx';
import {Utility} from 'app/common/utility';
import {Http, Response} from '@angular/http';
import {Title} from '@angular/platform-browser';
import {Common} from "@app/common/common";
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {AlertService} from "@app/common/alert.service";

declare var $: any;


@Component({
  selector: 'app-importcustomer',
  templateUrl: './importcustomer.component.html',
  styleUrls: ['./importcustomer.component.css']
})
export class ImportcustomerComponent implements OnInit {

    constructor(private http: Http, private title: Title, private route: ActivatedRoute, private router: Router, private alertService: AlertService) {
    }

    id: number;
    p: Number = 1;
    count: Number = 20;
    file: File;
    Common = Common;
    arrayBuffer: any;
    errorMessage = '';
    successMessage = '';
    missingColumnValidation = '';
    maxLimitValidation = '';
    filetypeValidation = '';
    missingColumns: object = [];
    customerlogs = [];
    code: string;
    importForm: FormGroup;
    event: any;
    interval;
    duration = 0;
    areaId: number;
    routes: number;

    getList() {
        this.code = 'CUST';
        this.http.get(Utility.serviceCoreURL + '/getimportlogs?code=' + this.code).subscribe(data => {
            this.customerlogs = data.json();
        });
    }

    selectFile(event) {
        if (event.target.files.length > 0) {
            this.event = event;

            let x = this.event.target.files[0].name.split('.');
            let extension = x[1];

            if (Utility.isOfImportType(extension)) {
                this.filetypeValidation = '';
            } else {
                this.filetypeValidation = 'Selected file type is must be .csv, .xls, .xlsx';
                this.alertService.validationAlert();
            }
        } else {
            this.filetypeValidation = '';
        }
    }

    ImportFile() {
        if (this.importForm.valid && this.filetypeValidation.length == 0) {
            if (confirm('Are you Sure, You want to import this file?')) {
                if (this.event.target.files.length > 0) {
                    this.interval = setInterval(() => {
                        this.duration = this.duration + 1;
                        if (this.successMessage.length > 0 || this.errorMessage.length > 0) {
                            this.duration = 100;
                            clearInterval(this.interval);
                        }
                    }, 1000);
                    this.file = this.event.target.files[0];
                    let fileReader = new FileReader();
                    fileReader.onload = (e) => {
                        this.arrayBuffer = fileReader.result;
                        let data = new Uint8Array(this.arrayBuffer);
                        let arr = new Array();
                        for (var i = 0; i != data.length; ++i) {
                            arr[i] = String.fromCharCode(data[i]);
                        }
                        let bstr = arr.join('');
                        let workbook = XLSX.read(bstr, {type: 'binary'});
                        let first_sheet_name = workbook.SheetNames[0];
                        let worksheet = workbook.Sheets[first_sheet_name];
                        let datasheet = XLSX.utils.sheet_to_json(worksheet, {raw: true, defval: ''});
                        let created_by = Utility.getLoggedUserId();
                        let area_id = this.areaId;
                        this.http.post(Utility.serviceCoreURL + '/importcustomer', {datasheet, created_by, area_id}
                        ).subscribe((res: Response) => {
                            if (res) {
                                this.duration = 100;
                                this.successMessage = 'Excel Sheet Import Successfully';
                                this.alertService.validationAlert();
                                this.getList();
                                /*res = res.json();
                                if (res['success'] == 'importFailed') {
                                  this.duration = 100;
                                  this.missingColumns = res['missingFields'];
                                  this.missingColumnValidation = 'Import Failed. ';
                                  for (let key in this.missingColumns) {
                                    let value = this.missingColumns[key];
                                    this.missingColumnValidation += value;
                                    this.missingColumnValidation += ' ,';
                                  }
                                  this.missingColumnValidation += ' columns not matching to format';
                                  this.getList();
                                } else if (res['success'] == 'maxlimitExceeded') {
                                  this.duration = 100;
                                  this.getList();
                                  this.maxLimitValidation = 'Maximum limit of records exceeded in sheet';
                                } else {
                                  this.duration = 100;
                                  this.getList();
                                  this.successMessage = 'Excel Sheet Import Successfully';
                                }*/
                            }
                        }, (err) => {
                            this.errorMessage = 'Something sent wrong';
                            this.alertService.validationAlert();
                        });
                    };
                    fileReader.readAsArrayBuffer(this.file);
                }
            }
        } else{
            this.alertService.validationAlert();
        }
    }

    ngOnInit() {
        $('.navbar-collapse li.active').removeClass('active');
        $('#menusales').addClass('active');
        this.getList();
        this.route.params.subscribe((res) => {
            this.routes = Common.getRoute(this.router.url);
        });
        this.http.get(Utility.serviceCoreURL + '/getidbysubarearoute?route=' + this.routes).subscribe((data) => {
            this.areaId = data.json();
        });
        this.importForm = new FormGroup({
            importfile: new FormControl('', Validators.required),
        });
        this.title.setTitle('Octanics - Customers');
    }


}
