import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Http, Response} from "@angular/http";
import {ActivatedRoute, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {Lookup} from "@app/common/lookup";
import {Utility} from 'app/common/utility';
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from "@angular/common/http";
import {Businessdocuments} from "@app/models/businessdocuments.model";
import {Common} from "@app/common/common";
import {AlertService} from "@app/common/alert.service";
import {Directors} from "@app/models/directors.model";
import {Businessaddresses} from "@app/models/businessaddresses.model";

class ImageSnippet {
    constructor(public src: string, public file: File) {
    }
}

declare var $: any;


@Component({
    selector: 'app-business',
    templateUrl: './business.component.html',
    styleUrls: ['./business.component.css']
})
export class BusinessComponent implements OnInit {

    constructor(private http: Http,
                private router: Router,
                private title: Title,
                private httpclient: HttpClient,
                private route: ActivatedRoute,
                private lookUp: Lookup,
                private alertService: AlertService) {
    }

    cities = [];
    states = [];
    countries = [];
    documentTypes = [];
    selectedFileOriginalName: string;
    businessDocumentType = null;
    businessDocumentTitle = null;
    BusinessForm: FormGroup;
    BusinessAddressForm: FormGroup;
    business: object = [];
    businessLogo: string = null;
    junctionbox = Utility.junctionboxPath();
    junctionBoxSubDomain = Utility.junctionBoxSubDomain();
    companyShortName = Utility.getCompanyShortName();
    business_id: any = localStorage.getItem('businessid');
    uploadingFile = false;
    uploadingBusinessLogoPercents = 0;
    uploadingBusinessDocumentPercents = 0;
    businessLogoFiletypeValidation = '';
    selectedBusinessLogoFile: ImageSnippet;
    selectedBusinessLogoFilePath: string = null;
    selectedBusinessDocumentFile: ImageSnippet;
    selectedBusinessDocumentFilePath: string = null;
    Utility = Utility;
    BusinessDocumentForm: FormGroup;
    businessDocuments: Array<Businessdocuments> = [];
    businessdocumentsValidation: boolean;
    documentEditIndex: number = -1;
    businessdocument_id: number;
    successMessage = '';
    areaId: number;
    routes: string;
    documentValidationMessage: string = '';
    documentSizeLimit = localStorage.getItem('docsizelimit');
    documentFileSizeLimit = this.formatBytes(this.documentSizeLimit);
    watermarklogo: string;
    companystamp: string;
    digitalsignature: string;
    selectedCompanyStampPath: string;
    uploadingCompanyStampPathPercents = 0;
    uploadingCompanyStampPath = false;
    companystampFiletypeValidation = '';
    selectedDigitalSignaturePath: string;
    uploadingDigitalSignaturePathPercents = 0;
    uploadingDigitalSignaturePath = false;
    digitalsignatureFiletypeValidation = '';
    selectedWatermarklogoFilePath: string;
    uploadingWatermarklogoPathPercents = 0;
    uploadingWatermarklogoPath = false;
    watermarklogoFiletypeValidation = '';
    selectedFile: ImageSnippet;
    selectedFilePath: string;
    directorFieldsValidation = '';
    directors: Array<Directors> = [];
    directoreditindex: number;
    businessaddresses: Array<Businessaddresses> = [];
    businessaddresseditindex: number;
    businessAddressFieldsValidation = '';
    cityId: number;
    stateId: number;
    address1: string;
    addresstypes = [];

    getLookUp() {
        this.lookUp.getLookUp('cities').subscribe((data) => {
            if (data) {
                this.cities = data;
            }
        });
        this.lookUp.getLookUp('states').subscribe((data) => {
            if (data) {
                this.states = data;
            }
        });
        this.lookUp.getLookUp('countries').subscribe((data) => {
            if (data) {
                this.countries = data;
            }
        });
        this.http.get(Utility.serviceCoreURL + '/getidbyarearoute?route=' + this.routes).subscribe((data) => {
            this.areaId=data.json();
            this.lookUp.getLookUp('documenttypes', this.areaId).subscribe((data) => {if (data) {this.documentTypes = data;}
            });
        });
        this.lookUp.getLookUp('addresstypes').subscribe((data) => {
            if (data) {
                this.addresstypes = data;
            }
        });
    }

    deleteBusinessLogo() {
        if (confirm('Are you Sure, You want to remove Business Logo?')) {
            this.businessLogo = null;
            $('#businesslogo').val('');
        }
    }

    uploadBusinessLogo(event) {
        if (event.target.files.length > 0) {
            let x = event.target.files[0].name.split('.');
            let extension = x[1];

            if (Utility.isOfImageType(extension)) {
                this.businessLogoFiletypeValidation = '';
                if (event.target.files) {
                    for (let i = 0; i < event.target.files.length; i++) {
                        let reader = new FileReader();
                        reader.readAsDataURL(event.target.files[i]);
                        reader.onload = (events: any) => {
                            this.selectedBusinessLogoFile = new ImageSnippet(events.target.result[i], event.target.files[i]);
                            this.uploadBusinessLogoDocument(this.selectedBusinessLogoFile.file);
                        };
                    }
                }
            } else {
                this.businessLogoFiletypeValidation = 'Selected business logo type must be .png, .jpeg, .jpg, .gif';
                this.alertService.validationAlert();
            }
        }
    }

    public uploadBusinessLogoDocument(businesslogo: any) {
        const formData = new FormData();
        formData.append('file', businesslogo);
        formData.append('filename', businesslogo.name);
        formData.append('filepath', this.companyShortName.replace(/\s/g, '').toLowerCase() + '/assets/businesslogo');
        formData.append('subdomain', this.junctionBoxSubDomain);

        let req = new HttpRequest('POST', Utility.serviceStorageURL() + '/uploadfile', formData, {
            reportProgress: true
        });

        this.httpclient.request(req).subscribe(event => {
            this.businessLogo = this.selectedBusinessLogoFilePath = event['body'];
            // Via this API, you get access to the raw event stream.
            if (event.type === HttpEventType.UploadProgress) {
                // This is an upload progress event. Compute and show the % done:
                this.uploadingBusinessLogoPercents = Math.round(100 * event.loaded / event.total);
                this.uploadingFile = true;
                // Look for upload progress events.
            } else if (event instanceof HttpResponse) {
                this.uploadingFile = false;
            }
        });
    }

    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    uploadBusinessDocument(event) {
        if (event.target.files.length > 0) {
            if (event.target.files[0].size <= this.documentSizeLimit) {
                this.documentValidationMessage = '';
                if (event.target.files) {
                    for (let i = 0; i < event.target.files.length; i++) {
                        let reader = new FileReader();
                        reader.readAsDataURL(event.target.files[i]);
                        reader.onload = (events: any) => {
                            this.selectedFileOriginalName = event.target.files[i].name;
                            this.selectedBusinessDocumentFile = new ImageSnippet(events.target.result[i], event.target.files[i]);
                            this.uploadBusinessDocuments(this.selectedBusinessDocumentFile.file);
                        };
                    }
                }
            } else {
                this.selectedBusinessDocumentFilePath = null;
                this.businessdocumentsValidation = true;
                this.selectedFileOriginalName = null;
                this.documentValidationMessage = 'Please upload document as per given size';
                this.alertService.validationAlert();
            }
        } else {
            this.selectedBusinessDocumentFilePath = null;
            this.selectedFileOriginalName = null;
            this.businessdocumentsValidation = true;
        }
    }

    public uploadBusinessDocuments(businessdocument: any) {
        const formData = new FormData();
        formData.append('file', businessdocument);
        formData.append('filename', businessdocument.name);
        formData.append('filepath', this.companyShortName.replace(/\s/g, '').toLowerCase() + '/assets/businessdocuments');
        formData.append('subdomain', this.junctionBoxSubDomain);

        let req = new HttpRequest('POST', Utility.serviceStorageURL() + '/uploadfile', formData, {
            reportProgress: true
        });

        this.httpclient.request(req).subscribe(event => {
            this.selectedBusinessDocumentFilePath = event['body'];
            // Via this API, you get access to the raw event stream.
            if (event.type === HttpEventType.UploadProgress) {
                // This is an upload progress event. Compute and show the % done:
                this.uploadingBusinessDocumentPercents = Math.round(100 * event.loaded / event.total);
                this.uploadingFile = true;
                // Look for upload progress events.
            } else if (event instanceof HttpResponse) {
                this.uploadingFile = false;
            }
        });
    }

    onDocumentTypeChange() {
        if (!Utility.isNull(this.businessDocumentType)) {
            let documenttype = this.documentTypes.filter(x => x.id == this.businessDocumentType);
            if (documenttype[0]['name'].trim().toUpperCase() === 'OTHER')
                $('.OtherDocumentType').show();
            else {
                $('.OtherDocumentType').hide();
                $('#otherdocumenttype').val('');
            }
        } else {
            $('.OtherDocumentType').hide();
            $('#otherdocumenttype').val('');
        }
    }

    insertBusinessDocument() {
        if (!Utility.isNull(this.selectedBusinessDocumentFilePath && this.documentValidationMessage.length <= 0)) {
            if (this.BusinessDocumentForm.controls.documenttype_id.valid) {
                this.businessdocumentsValidation = false;
                this.insertBusinessDocuments();
            }
        } else {
            if (this.BusinessDocumentForm.controls.documenttype_id.valid && this.BusinessDocumentForm.controls.document.valid && this.documentValidationMessage.length <= 0) {
                this.businessdocumentsValidation = false;
                this.insertBusinessDocuments();
            } else {
                this.businessdocumentsValidation = true;
                this.alertService.validationAlert();
            }
        }
    }

    insertBusinessDocuments() {
        let document = this.documentTypes.filter(x => x.id == this.businessDocumentType);
        if (this.documentEditIndex !== undefined && this.documentEditIndex >= 0) {
            this.businessDocuments[this.documentEditIndex].documenttype_id = this.businessDocumentType;
            this.businessDocuments[this.documentEditIndex].documentname = document[0].name;
            this.businessDocuments[this.documentEditIndex].title = this.businessDocumentTitle;
            this.businessDocuments[this.documentEditIndex].documentpath = this.selectedBusinessDocumentFilePath;
            this.businessDocuments[this.documentEditIndex].originalfilename = this.selectedFileOriginalName;
            this.businessDocuments[this.documentEditIndex].otherdocumenttype = $('#otherdocumenttype').val().trim();

            if (this.businessdocument_id > 0)
                this.businessDocuments[this.documentEditIndex].entitystate = 'Modified';
            else
                this.businessDocuments[this.documentEditIndex].entitystate = 'Added';
        } else {
            let customObj = new Businessdocuments();
            customObj.documenttype_id = this.businessDocumentType;
            customObj.documentname = document[0].name;
            customObj.title = this.businessDocumentTitle;
            customObj.documentpath = this.selectedBusinessDocumentFilePath;
            customObj.originalfilename = this.selectedFileOriginalName;
            customObj.otherdocumenttype = $('#otherdocumenttype').val().trim();
            customObj.entitystate = 'Added';
            this.businessDocuments.push(customObj);
        }
        this.clearBusinessDocumentControls();
    }

    editBusinessDocument(i, editdata) {
        this.businessdocument_id = editdata.id;
        this.businessDocumentType = editdata.documenttype_id;
        this.selectedBusinessDocumentFilePath = editdata.documentpath;
        this.businessDocumentTitle = editdata.title;
        this.selectedFileOriginalName = editdata.originalfilename;

        $('#otherdocumenttype').val(editdata.otherdocumenttype);
        this.onDocumentTypeChange();
        this.documentEditIndex = i;

        if (!Utility.isNull(this.selectedBusinessDocumentFilePath)) {
            $('.showDocPreview').show();
            this.businessdocumentsValidation = false;
        } else {
            $('.showDocPreview').hide();
        }
    }

    deleteBusinessDocument(id) {
        if (confirm('Are you sure you want to delete this record?')) {
            if (this.businessDocuments[id].id != undefined) {
                this.businessDocuments[id].entitystate = 'Deleted';
            } else {
                this.businessDocuments.splice(id, 1);
            }
        }
    }

    clearBusinessDocumentControls() {
        $('#frmBusinessDocumentsUpload').get(0).reset();
        this.businessDocumentType = null;
        $('.showDocPreview').hide();
        this.businessdocument_id = null;
        this.selectedFileOriginalName = null;
        this.selectedBusinessDocumentFilePath = null;
        this.businessDocumentTitle = null;
        this.documentEditIndex = -1;
        $('.OtherDocumentType').hide();
        $('#otherdocumenttype').val('');
        $('#document').val('');
    }

    saveBusiness(business) {
        if (this.BusinessForm.valid && this.business_id > 0) {
            // if (Utility.isNull(this.business)) {
            business.id = this.business_id;
            business.businessdocuments = this.businessDocuments;
            business.businesslogo = this.businessLogo;
            business.director = this.directors;
            business.businessaddresses = this.businessaddresses;
            business.watermarklogo = this.selectedWatermarklogoFilePath ? this.selectedWatermarklogoFilePath : this.watermarklogo;
            business.companystamp = this.selectedCompanyStampPath ? this.selectedCompanyStampPath : this.companystamp;
            business.digitalsignature = this.selectedDigitalSignaturePath ? this.selectedDigitalSignaturePath : this.digitalsignature;
            business.created_by = business.modified_by = business.deleted_by = Utility.getLoggedUserId();
            this.http.post(Utility.serviceCoreURL + '/updatebusiness', business).subscribe((res: Response) => {
                if (res) {
                    this.successMessage = 'Business updated successfully';
                    this.getBusinessDetails();
                    this.clearBusinessDocumentControls();
                    $('#businesslogo').val('');
                    this.alertService.validationAlert();
                }
            });
            // }
            // else {
            //   business.id = this.employers['id'];
            //   business.modified_by = Utility.getLoggedUserId();
            //   business.director = this.directors;
            //   business.employeraddresses = this.employeraddresses;
            //   business.companylogo = this.selectedFilePath ? this.selectedFilePath : this.companylogo;
            //   business.watermarklogo = this.selectedWatermarklogoFilePath ? this.selectedWatermarklogoFilePath : this.watermarklogo;
            //   business.companystamp = this.selectedCompanyStampPath ? this.selectedCompanyStampPath : this.companystamp;
            //   business.digitalsignature = this.selectedDigitalSignaturePath ? this.selectedDigitalSignaturePath : this.digitalsignature;
            //   this.http.post(Configuration.serviceCoreURL + '/updatebusiness', business).subscribe((res: Response) => {
            //       if (res) {
            //           this.getBusinessDetails();
            //           this.successMessage = 'Business Updated Successfully';
            //           this.alertService.validationAlert();
            //       }
            //   });
            // }
        } else {
            this.alertService.validationAlert();
        }
    }

    uploadWatermarkMultiple(event) {
        // console.log(event);
        if (event.target.files.length > 0) {
            let x = event.target.files[0].name.split('.');
            let extension = x[1];

            if (Utility.isOfImageType(extension)) {
                this.watermarklogoFiletypeValidation = '';
                if (event.target.files) {
                    for (let i = 0; i < event.target.files.length; i++) {
                        let reader = new FileReader();
                        reader.readAsDataURL(event.target.files[i]);
                        reader.onload = (events: any) => {
                            this.selectedFile = new ImageSnippet(events.target.result[i], event.target.files[i]);
                            // console.log(this.selectedFile);
                            this.uploadWatermarklogoDocument(this.selectedFile.file);
                        };
                    }
                }
            } else {
                this.watermarklogoFiletypeValidation = 'Selected Company logo type must be .png, .jpeg, .jpg';
                this.alertService.validationAlert();
            }
        } else {
            this.watermarklogoFiletypeValidation = '';
        }
    }
    public uploadWatermarklogoDocument(watermarklogo: any) {
        // console.log(watermarklogo);
        const formData = new FormData();
        formData.append('file', watermarklogo);
        formData.append('filename', watermarklogo.name);
        formData.append('filepath', this.companyShortName.replace(/\s/g, '').toLowerCase() + '/assets/employerwatermarklogo');
        formData.append('subdomain', this.junctionBoxSubDomain);

        let req = new HttpRequest('POST', Utility.serviceStorageURL() + '/uploadfile', formData, {
            reportProgress: true
        });
        this.httpclient.request(req).subscribe(event => {
            this.selectedWatermarklogoFilePath = this.watermarklogo = event['body'];
            // console.log(this.selectedWatermarklogoFilePath);
            // Via this API, you get access to the raw event stream.
            if (event.type === HttpEventType.UploadProgress) {
                // This is an upload progress event. Compute and show the % done:
                this.uploadingWatermarklogoPathPercents = Math.round(100 * event.loaded / event.total);
                this.uploadingWatermarklogoPath = true;
                // Look for upload progress events.
            } else if (event instanceof HttpResponse) {
                this.uploadingWatermarklogoPath = false;
            }
        });
    }

    deleteWatermarkLogo() {
        if (confirm('Are you Sure, You want to remove Company Logo?')) {
            this.watermarklogo = null;
            $('#watermarklogo').val('');
        }
    }

    uploadCompanyStampMultiple(event) {
        if (event.target.files.length > 0) {
            let x = event.target.files[0].name.split('.');
            let extension = x[1];

            if (Utility.isOfImageType(extension)) {
                this.companystampFiletypeValidation = '';
                if (event.target.files) {
                    for (let i = 0; i < event.target.files.length; i++) {
                        let reader = new FileReader();
                        reader.readAsDataURL(event.target.files[i]);
                        reader.onload = (events: any) => {
                            this.selectedFile = new ImageSnippet(events.target.result[i], event.target.files[i]);
                            this.uploadCompanyStampDocument(this.selectedFile.file);
                        };
                    }
                }
            } else {
                this.companystampFiletypeValidation = 'Selected Company stamp type must be .png, .jpeg, .jpg';
                this.alertService.validationAlert();
            }
        } else {
            this.companystampFiletypeValidation = '';
        }
    }

    public uploadCompanyStampDocument(companystamp: any) {
        const formData = new FormData();
        formData.append('file', companystamp);
        formData.append('filename', companystamp.name);
        formData.append('filepath', this.companyShortName.replace(/\s/g, '').toLowerCase() + '/assets/employerdocuments');
        formData.append('subdomain', this.junctionBoxSubDomain);

        let req = new HttpRequest('POST', Utility.serviceStorageURL() + '/uploadfile', formData, {
            reportProgress: true
        });

        this.httpclient.request(req).subscribe(event => {
            this.selectedCompanyStampPath = this.companystamp = event['body'];
            // Via this API, you get access to the raw event stream.
            if (event.type === HttpEventType.UploadProgress) {
                // This is an upload progress event. Compute and show the % done:
                this.uploadingCompanyStampPathPercents = Math.round(100 * event.loaded / event.total);
                this.uploadingCompanyStampPath = true;
                // Look for upload progress events.
            } else if (event instanceof HttpResponse) {
                this.uploadingCompanyStampPath = false;
            }
        });
    }

    deleteCompanyStamp() {
        if (confirm('Are you Sure, You want to remove Company Stamp?')) {
            this.companystamp = null;
            $('#companystamp').val('');
        }
    }

    uploadDigitalSignatureMultiple(event) {
        if (event.target.files.length > 0) {
            let x = event.target.files[0].name.split('.');
            let extension = x[1];

            if (Utility.isOfImageType(extension)) {
                this.digitalsignatureFiletypeValidation = '';
                if (event.target.files) {
                    for (let i = 0; i < event.target.files.length; i++) {
                        let reader = new FileReader();
                        reader.readAsDataURL(event.target.files[i]);
                        reader.onload = (events: any) => {
                            this.selectedFile = new ImageSnippet(events.target.result[i], event.target.files[i]);
                            this.uploadDigitalSignatureDocument(this.selectedFile.file);
                        };
                    }
                }
            } else {
                this.digitalsignatureFiletypeValidation = 'Selected Digital signature type must be .png, .jpeg, .jpg';
                this.alertService.validationAlert();
            }
        } else {
            this.digitalsignatureFiletypeValidation = '';
        }
    }

    public uploadDigitalSignatureDocument(digitalsignature: any) {
        const formData = new FormData();
        formData.append('file', digitalsignature);
        formData.append('filename', digitalsignature.name);
        formData.append('filepath', this.companyShortName.replace(/\s/g, '').toLowerCase() + '/assets/employerdocuments');
        formData.append('subdomain', this.junctionBoxSubDomain);
        let req = new HttpRequest('POST', Utility.serviceStorageURL() + '/uploadfile', formData, {
            reportProgress: true
        });

        this.httpclient.request(req).subscribe(event => {
            this.selectedDigitalSignaturePath = this.digitalsignature = event['body'];
            // Via this API, you get access to the raw event stream.
            if (event.type === HttpEventType.UploadProgress) {
                // This is an upload progress event. Compute and show the % done:
                this.uploadingDigitalSignaturePathPercents = Math.round(100 * event.loaded / event.total);
                this.uploadingDigitalSignaturePath = true;
                // Look for upload progress events.
            } else if (event instanceof HttpResponse) {
                this.uploadingDigitalSignaturePath = false;
            }
        });
    }

    deleteDigitalSignature() {
        if (confirm('Are you Sure, You want to remove Digital Signature?')) {
            this.digitalsignature = null;
            $('#digitalsignature').val('');
        }
    }

    insertBusinessAddress(employeraddress) {
        if (this.BusinessAddressForm.valid) {
            if (this.businessaddresseditindex !== undefined && this.businessaddresseditindex >= 0) {
                this.businessaddresses[this.businessaddresseditindex].addresstype_id = $('#addresstype_id option:selected').val() === undefined ? null : $('#addresstype_id option:selected').val();
                this.businessaddresses[this.businessaddresseditindex].addresstype = $('#addresstype_id option:selected').val() === 'null' ? null : $('#addresstype_id option:selected').text();
                this.businessaddresses[this.businessaddresseditindex].email = $('#email').val();
                this.businessaddresses[this.businessaddresseditindex].address1 = $('#address1').val();
                this.businessaddresses[this.businessaddresseditindex].address2 = $('#address2').val();
                // this.employerAddresses[this.employeraddresseditindex].city_id = $('#city_id option:selected').val();
                this.businessaddresses[this.businessaddresseditindex].city_id = $('#city_id option:selected').val() === undefined ? null : $('#city_id option:selected').val();
                this.businessaddresses[this.businessaddresseditindex].city = $('#city_id option:selected').val() === 'null' ? null : $('#city_id option:selected').text();
                this.businessaddresses[this.businessaddresseditindex].state_id = $('#state_id option:selected').val() === undefined ? null : $('#state_id option:selected').val();
                this.businessaddresses[this.businessaddresseditindex].state = $('#state_id option:selected').val() === 'null' ? null : $('#state_id option:selected').text();
                this.businessaddresses[this.businessaddresseditindex].country_id = $('#country_id option:selected').val() === undefined ? null : $('#country_id option:selected').val();
                this.businessaddresses[this.businessaddresseditindex].country = $('#country_id option:selected').val() === 'null' ? null : $('#country_id option:selected').text();
                this.businessaddresses[this.businessaddresseditindex].pincode = $('#pincode').val();
                this.businessaddresses[this.businessaddresseditindex].telephone = $('#telephone').val();
                this.businessaddresses[this.businessaddresseditindex].mobilenoofowner = $('#mobilenoofowner').val();
                if ($('#businessaddress_id').val() > 0) {
                    this.businessaddresses[this.businessaddresseditindex].entitystate = 'Modified';
                } else {
                    this.businessaddresses[this.businessaddresseditindex].entitystate = 'Added';
                }
            } else {
                const customObj = new Businessaddresses();
                // customObj.addresstype_id = $('#addresstype_id').val();
                customObj.addresstype_id = $('#addresstype_id option:selected').val() === undefined ? null : $('#addresstype_id option:selected').val();
                customObj.addresstype = $('#addresstype_id option:selected').val() === 'null' ? null : $('#addresstype_id option:selected').text();
                customObj.email = $('#email').val();
                customObj.address1 = $('#address1').val();
                customObj.address2 = $('#address2').val();
                customObj.city_id = $('#city_id option:selected').val() === undefined ? null : $('#city_id option:selected').val();
                customObj.city = $('#city_id option:selected').val() === 'null' ? null : $('#city_id option:selected').text();
                customObj.state_id = $('#state_id option:selected').val() === undefined ? null : $('#state_id option:selected').val();
                customObj.state = $('#state_id option:selected').val() === 'null' ? null : $('#state_id option:selected').text();
                customObj.country_id = $('#country_id option:selected').val() === undefined ? null : $('#country_id option:selected').val();
                customObj.country = $('#country_id option:selected').val() === 'null' ? null : $('#country_id option:selected').text();
                customObj.pincode = $('#pincode').val();
                customObj.telephone = $('#telephone').val();
                customObj.mobilenoofowner = $('#mobilenoofowner').val();
                customObj.entitystate = 'Added';
                this.businessaddresses.push(customObj);
            }
            this.clearBusinessAddressControls();
        } else {
            this.alertService.validationAlert();
        }
    }

    editBusinessAddress(i, editdata) {
        this.cityId = editdata.city_id;
        this.stateId = editdata.state_id;
        this.address1 = editdata.address1;
        $('#businessaddress_id').val(editdata.id);
        $('#addresstype_id').val(editdata.addresstype_id);
        $('#email').val(editdata.email);
        // $('#address1').val(editdata.address1);
        $('#address2').val(editdata.address2);
        //  $('#city_id').val(editdata.city_id);
        //  $('#state_id').val(editdata.state_id);
        $('#country_id').val(editdata.country_id);
        $('#pincode').val(editdata.pincode);
        $('#telephone').val(editdata.telephone);
        $('#mobilenoofowner').val(editdata.mobilenoofowner);
        this.businessaddresseditindex = i;
    }

    deleteBusinessAddress(id) {
        if (confirm("Are you sure you want to delete this record?")) {
            if (this.businessaddresses[id].id !== undefined) {
                this.businessaddresses[id].entitystate = "Deleted";
            } else {
                this.businessaddresses.splice(id, 1);
            }
        }
    }

    clearBusinessAddressControls() {
        $('#addresstype_id').val('');
        $('#email').val('');
        $('#address1').val('');
        $('#address2').val('');
        $('#city_id').val('');
        $('#state_id').val('');
        $('#country_id').val('');
        $('#pincode').val('');
        $('#telephone').val('');
        $('#mobilenoofowner').val('');
        $('#frmbusinessaddress').get(0).reset();
        this.businessaddresseditindex = -1;
    }

    insertdirectors(directors) {
        if ($('#firstname').val() != undefined && $('#firstname').val() != '') {
            this.directorFieldsValidation = '';
            if (this.directoreditindex !== undefined && this.directoreditindex >= 0) {
                this.directors[this.directoreditindex].firstname = $('#firstname').val();
                this.directors[this.directoreditindex].lastname = $('#lastname').val();
                this.directors[this.directoreditindex].contactno = $('#contactno').val();
                this.directors[this.directoreditindex].director = $('#firstname').val() + " " + $('#lastname').val();
                if ($('#director_id').val() > 0) {
                    this.directors[this.directoreditindex].entitystate = 'Modified';
                } else {
                    this.directors[this.directoreditindex].entitystate = 'Added';
                }
            } else {
                const customObj = new Directors();
                customObj.firstname = $('#firstname').val();
                customObj.lastname = $('#lastname').val();
                customObj.contactno = $('#contactno').val();
                customObj.director = $('#firstname').val() + " " + $('#lastname').val();
                customObj.entitystate = 'Added';
                this.directors.push(customObj);
            }
            this.clearDirectorsControls();
            $('.modal').modal('hide');
        } else {
            this.directorFieldsValidation = 'First Name is required';
        }
    }

    editdirector(i, editdata) {
        this.clearDirectorsControls();
        $('#director_id').val(editdata.id);
        $('#firstname').val(editdata.firstname);
        $('#lastname').val(editdata.lastname);
        $('#contactno').val(editdata.contactno);
        this.directoreditindex = i;
    }

    deletedirector(id) {
        if (confirm("Are you sure you want to delete this record?")) {
            if (this.directors[id].id !== undefined) {
                this.directors[id].entitystate = "Deleted";
            } else {
                this.directors.splice(id, 1);
            }
        }
    }

    clearDirectorsControls() {
        $('#firstname').val('');
        $('#lastname').val('');
        $('#contactno').val('');
        this.directorFieldsValidation = '';
        this.directoreditindex = -1;
    }

    getBusinessDetails() {
        this.http.get(Utility.serviceCoreURL + '/getbusinessdetails?business_id=' + this.business_id).subscribe(data => {
            data = data.json();
            this.business = data['businessdetails'];
            this.businessDocuments = data['businessdocuments'];
            this.directors = data['directors'];
            this.businessaddresses = data['businessaddresses'];

            if (!Utility.isNull(this.business)) {
                this.BusinessForm.controls['nameofbusiness'].setValue(this.business['name']);
                this.businessLogo = this.business['businesslogo'];
                this.watermarklogo = this.business['watermarklogo'];
                // console.log(this.watermarklogo);
                this.companystamp = this.business['companystamp'];
                this.digitalsignature = this.business['digitalsignature'];
                this.BusinessForm.controls['gstnumber'].setValue(this.business['gstnumber']);
                this.BusinessForm.controls['saccode'].setValue(this.business['saccode']);
                this.BusinessForm.controls['employercode'].setValue(this.business['employercode']);
                this.BusinessForm.controls['email'].setValue(this.business['email']);
                this.BusinessForm.controls['street1'].setValue(this.business['street1']);
                this.BusinessForm.controls['street2'].setValue(this.business['street2']);
                this.BusinessForm.controls['city_id'].setValue(this.business['city_id']);
                this.BusinessForm.controls['state_id'].setValue(this.business['state_id']);
                this.BusinessForm.controls['country_id'].setValue(this.business['country_id']);
                this.BusinessForm.controls['pincode'].setValue(this.business['pincode']);
                this.BusinessForm.controls['bankcity_id'].setValue(this.business['bankcity_id']);
                this.BusinessForm.controls['bankstate_id'].setValue(this.business['bankstate_id']);
                this.BusinessForm.controls['bankcountry_id'].setValue(this.business['bankcountry_id']);
                this.BusinessForm.controls['bankpincode'].setValue(this.business['bankpincode']);
                this.BusinessForm.controls['telephone'].setValue(this.business['telephone']);
                this.BusinessForm.controls['registrationnumber'].setValue(this.business['registrationnumber']);
                this.BusinessForm.controls['mobilenumber'].setValue(this.business['mobilenumber']);
                this.BusinessForm.controls['tan'].setValue(this.business['tan']);
                this.BusinessForm.controls['pancardno'].setValue(this.business['pancardno']);
                this.BusinessForm.controls['incometaxid'].setValue(this.business['incometaxid']);
                this.BusinessForm.controls['ifsc'].setValue(this.business['ifsc']);
                this.BusinessForm.controls['bankname'].setValue(this.business['bankname']);
                this.BusinessForm.controls['bankaccountno'].setValue(this.business['bankaccountno']);
                this.BusinessForm.controls['bankaccountname'].setValue(this.business['bankaccountname']);
                this.BusinessForm.controls['bankbranchname'].setValue(this.business['bankbranchname']);
                this.BusinessForm.controls['bankstreet1'].setValue(this.business['bankaddress_1']);
                this.BusinessForm.controls['bankstreet2'].setValue(this.business['bankaddress_2']);
                this.BusinessForm.controls['bankstreet3'].setValue(this.business['bankaddress_3']);
                this.BusinessForm.controls['customercarenumber'].setValue(this.business['customercarenumber']);
                this.BusinessForm.controls['customercareemail'].setValue(this.business['customercareemail']);
                this.BusinessForm.controls['salesnumber'].setValue(this.business['salesnumber']);
            }

            $("#success-alert").fadeTo(2000, 500).slideUp(500, function(){
                $("#success-alert").slideUp(500);
            });
        });
    }

    ngOnInit() {
        this.title.setTitle('Octanics - Business');

        this.route.params.subscribe((res) => {
            this.routes = Common.getRoute(this.router.url);
        });

        this.getLookUp();

        this.BusinessForm = new FormGroup({
            nameofbusiness: new FormControl('', Validators.required),
            businesslogo: new FormControl(null),
            registrationnumber: new FormControl(null),
            pancardno: new FormControl(null),
            tan: new FormControl(null),
            incometaxid: new FormControl(null),
            gstnumber: new FormControl(null),
            saccode: new FormControl(null),
            watermarklogo: new FormControl(null),
            digitalsignature: new FormControl(null),
            companystamp: new FormControl(null),
            employercode: new FormControl(null),
            telephone: new FormControl(null),
            mobilenumber: new FormControl(null),
            email: new FormControl(null),
            street1: new FormControl(null),
            street2: new FormControl(null),
            city_id: new FormControl(null),
            state_id: new FormControl(null),
            country_id: new FormControl(null),
            pincode: new FormControl(null),

            bankaccountno: new FormControl(null),
            bankaccountname: new FormControl(null),
            bankname: new FormControl(null),
            bankbranchname: new FormControl(null),
            ifsc: new FormControl(null),
            bankstreet1: new FormControl(null),
            bankstreet2: new FormControl(null),
            bankstreet3: new FormControl(null),
            bankcity_id: new FormControl(null),
            bankstate_id: new FormControl(null),
            bankcountry_id: new FormControl(null),
            bankpincode: new FormControl(null),
            customercarenumber: new FormControl(null),
            customercareemail: new FormControl(null),
            salesnumber: new FormControl(null),
        });

        this.BusinessAddressForm = new FormGroup({
            addresstype_id: new FormControl(null),
            email: new FormControl(null),
            address1: new FormControl('', Validators.required),
            address2: new FormControl(null),
            city_id: new FormControl('', Validators.required),
            state_id: new FormControl('', Validators.required),
            country_id: new FormControl(null),
            pincode: new FormControl(null),
            telephone: new FormControl(null),
            mobilenoofowner: new FormControl(null),
        });

        this.BusinessDocumentForm = new FormGroup({
            documenttype_id: new FormControl('', Validators.required),
            title: new FormControl('', null),
            document: new FormControl('', Validators.required)
        });

        this.getBusinessDetails();

        if (this.junctionBoxSubDomain == 'null') {
            this.junctionBoxSubDomain = null;
        }
    }

}
