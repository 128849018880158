import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Utility} from 'app/common/utility';
import {FormGroup, Validators, FormControl} from '@angular/forms';
import {HttpClient, HttpEventType, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
import {Validate} from 'app/common/validate';
import {Lookup} from 'app/common/lookup';
import {DatePipe} from '@angular/common';
import {Common} from "@app/common/common";
import {Title} from '@angular/platform-browser';
import {PopupService} from "@app/common/popup.service";
import {AlertService} from "@app/common/alert.service";
import {Campaign} from "@app/models/campaign.model";
import {filter} from 'rxjs/operators';
import {Location} from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-updatecampaign',
  templateUrl: './updatecampaign.component.html',
  styleUrls: ['./updatecampaign.component.css']
})
export class UpdatecampaignComponent implements OnInit {
  previousUrl: string = null;
  currentUrl: string = null;
  arrayOfValues: Array<string>;
  constructor(private http: Http, private title: Title, private router: Router,
  private httpclient: HttpClient, private route: ActivatedRoute,
  private lookUp: Lookup, private datePipe: DatePipe, private alertService: AlertService,
  private popup: PopupService, private _location: Location, private activatedRoute: ActivatedRoute) { }

  frequency_id;
  Validate = Validate;
  CampaignForm: FormGroup;
  campaign: Campaign;
  campaignDetails: any;
  form: FormGroup;
  templates = [];
  campaignstatus = [];
  id: number;
  campaigndetails = [];
  frequency = [];
  draftId: number;
  campaigns: object = {};
  templatetype: string;
  campaignId: number;
  campaignlogs = [];
  code: string;
  emailtemplates = [];
  smstemplates = [];
  whatsapptemplates = [];


  getLookUp() {
    this.lookUp.getLookUp('campaignstatus').subscribe((data) => {
      if (data) {
        this.campaignstatus = data;
        let type = this.campaignstatus.find(x => x.code == 'DRAFT');
        this.draftId = type['id'];

      }
    });

    this.lookUp.getLookUp('emailtemplates').subscribe((data) => {
      if (data) {
        this.emailtemplates = data;
      }
    });
    this.lookUp.getLookUp('smstemplates').subscribe((data) => {
      if (data) {
        this.smstemplates = data;
      }
    });
    this.lookUp.getLookUp('whatsapptemplates').subscribe((data) => {
      if (data) {
        this.whatsapptemplates = data;
      }
    });
    this.lookUp.getLookUp('frequency').subscribe((data) => {
      if (data) {
        this.frequency = data;
      }
    });
  }

  updateCampaign(campaign) {
    if (this.CampaignForm.valid) {
      campaign.created_by = Utility.getLoggedUserId();
      campaign.modified_by = Utility.getLoggedUserId();
      campaign.id = this.id;
      campaign.customer_id = this.arrayOfValues;
      campaign.date = $('#date').val();
      campaign.business_id = localStorage.getItem('businessid');
      campaign.frequency_id = ( this.frequency_id != null && this.frequency_id !== undefined ) ? this.frequency_id : null;
      this.http.post(Utility.serviceCoreURL + "/updateCampaign", campaign).subscribe((res: Response) => {
        if (res) {
          this.router.navigate(['/campaigns']);
        }
      });
    } else {
      this.alertService.validationAlert();
    }
  }

  getCustomer(customerid) {
    this.http.get(Utility.serviceCoreURL + '/getcustomer', {params: {'customerid[]': customerid}}).subscribe(data => {
      this.campaigndetails = data.json();
    });
  }

  priviousUrl() {
    this._location.back();
  }

  radioChecked(id, i) {
    this.frequency_id = id;
    this.frequency.forEach(freq => {
      if (freq.id !== id) {
        freq.selected = false;
      } else {
        freq.selected = true;
      }
    });
  }
  deleteCustomer = function (id) {
    if (confirm('Are you sure you want to delete this record?')) {
      const index = this.campaigndetails.findIndex(user => user.id === id);
      this.campaigndetails.splice(index, 1);
      this.arrayOfValues.splice(index, 1);
    }
  };
  getCampaignDetails (){
    this.http.get(Utility.serviceCoreURL + '/campaigndetails?id=' + this.id).subscribe(data => {
      data = data.json();
      this.campaigns = data['campaigns'];
      this.campaignDetails = data['campaigndetails'];
      this.templatetype = data['campaigns']['templatetype'];
    });

  }
  ngOnInit() {

    $('.navbar-collapse li.active').removeClass('active');
    $('#menusales').addClass('active');

    this.CampaignForm = new FormGroup({
      template_id: new FormControl('', Validators.required),
      campaignstatus_id: new FormControl(null),
      //freq: new FormControl(null),
      name: new FormControl('', Validators.required),
      description: new FormControl(null)
      // date: new FormControl(null)
      //  timeofrun: new FormControl(null)
    });
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
    });

    this.getLookUp();
    this.title.setTitle('Octanics - Email Campaign');
    this.route.paramMap.subscribe(params => {
      this.id = Common.decryptId((params.get('id')));
    });
    this.getCampaignDetails();
  }

}
