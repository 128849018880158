import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {routing} from './app.routes';
import {AppComponent} from './app.component';
import {LayoutComponent} from './shared/layout/layout.component';
import {HeaderComponent} from './shared/header/header.component';
import {FooterComponent} from './shared/footer/footer.component';
import {LoginComponent} from './login/login.component';
import {ContactlistComponent} from "@app/audience/contact/contactlist/contactlist.component";
import {InsertcontactComponent} from "@app/audience/contact/insertcontact/insertcontact.component";
import {UpdatecontactComponent} from "@app/audience/contact/updatecontact/updatecontact.component";
import {ContactviewComponent} from "@app/audience/contact/contactview/contactview.component";
import {PaginationComponent} from './pagination/pagination.component';
import {ExportcontactComponent} from "@app/audience/contact/exportcontact/exportcontact.component";
import {ImportcontactComponent} from "@app/audience/contact/importcontact/importcontact.component";
import {EmailComponent} from "@app/marketing/email/email.component";
import {SmsComponent} from "@app/marketing/sms/sms.component";
import {WhatsappComponent} from "@app/marketing/whatsapp/whatsapp.component";
import {ChangepasswordComponent} from './changepassword/changepassword.component';
import {ProfileComponent} from './myprofile/profile/profile.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {RedirectComponent} from './redirect/redirect.component';
import {Localstorage} from './common/localstorage';
import {Securestorage} from './common/securestorage';
import {TokenService} from './common/token.service';
import {AuthGuard} from './auth.guard';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SettingsComponent} from './settings/settings.component';
import {HttpModule} from '@angular/http';
import {Lookup} from "@app/common/lookup";
import {CountrylistComponent} from './masters/common/country/countrylist/countrylist.component';
import {InsertcountryComponent} from './masters/common/country/insertcountry/insertcountry.component';
import {UpdatecountryComponent} from './masters/common/country/updatecountry/updatecountry.component';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {PaginationModule} from './pagination/pagination.module';
import {ValidationerrorModule} from "@app/validationerror/validationerror.module";
import {AlertService} from "@app/common/alert.service";
import {StatelistComponent} from './masters/common/state/statelist/statelist.component';
import {InsertstateComponent} from './masters/common/state/insertstate/insertstate.component';
import {UpdatestateComponent} from './masters/common/state/updatestate/updatestate.component';
import {CitylistComponent} from './masters/common/city/citylist/citylist.component';
import {InsertcityComponent} from './masters/common/city/insertcity/insertcity.component';
import {UpdatecityComponent} from './masters/common/city/updatecity/updatecity.component';
import {DocumenttypelistComponent} from './masters/common/documenttype/documenttypelist/documenttypelist.component';
import {InsertdocumenttypeComponent} from './masters/common/documenttype/insertdocumenttype/insertdocumenttype.component';
import {UpdatedocumenttypeComponent} from './masters/common/documenttype/updatedocumenttype/updatedocumenttype.component';
import {CustomerlistComponent} from './audience/customer/customerlist/customerlist.component';
import {InsertcustomerComponent} from './audience/customer/insertcustomer/insertcustomer.component';
import {UpdatecustomerComponent} from './audience/customer/updatecustomer/updatecustomer.component';
import {CustomerviewComponent} from './audience/customer/customerview/customerview.component';
import {PopupService} from "./common/popup.service";
import {ExportcustomerComponent} from './audience/customer/exportcustomer/exportcustomer.component';
import {ImportcustomerComponent} from './audience/customer/importcustomer/importcustomer.component';
import {EmployerComponent} from './hr/employer/employer.component';
import {InsightdashboardComponent} from './insights/insightdashboard/insightdashboard.component';
import {DocumentsComponent} from './datamanagement/documents/documents.component';
import {ParametersComponent} from './configuration/parameters/parameters.component';
import {CampaignlistComponent} from './insights/campaign/campaignlist/campaignlist.component';
import {TemplatelistComponent} from './datamanagement/templates/templatelist/templatelist.component';
import {InserttemplateComponent} from './datamanagement/templates/inserttemplate/inserttemplate.component';
import {UpdatetemplateComponent} from './datamanagement/templates/updatetemplate/updatetemplate.component';
import {FroalaEditorModule, FroalaViewModule} from "angular-froala-wysiwyg";
import {TemplateviewComponent} from './datamanagement/templates/templateview/templateview.component';
import {InsertcampaignComponent} from './insights/campaign/insertcampaign/insertcampaign.component';
import {UpdatecampaignComponent} from './insights/campaign/updatecampaign/updatecampaign.component';
import {CampaignviewComponent} from './insights/campaign/campaignview/campaignview.component';
import {EmployeelistComponent} from './audience/employee/employeelist/employeelist.component';
import {InsertemployeeComponent} from './audience/employee/insertemployee/insertemployee.component';
import {UpdateemployeeComponent} from './audience/employee/updateemployee/updateemployee.component';
import {EmployeeviewComponent} from './audience/employee/employeeview/employeeview.component';
import {BirthdaylistComponent} from './greetings/birthday/birthdaylist/birthdaylist.component';
import {WorkanniversarylistComponent} from './greetings/workanniversary/workanniversarylist/workanniversarylist.component';
import {TurbobusinesscustomerComponent} from './backoffice/processingcenter/turbobusinesscustomer/turbobusinesscustomer.component';
import {SidemenuComponent} from './shared/sidemenu/sidemenu.component';
import {SidemenulayoutComponent} from './shared/sidemenulayout/sidemenulayout.component';
import {BusinessComponent} from "@app/configuration/business/business.component";
import {EmaillistComponent} from "@app/datamanagement/templates/email/emaillist/emaillist.component";
import {InsertemailComponent} from "@app/datamanagement/templates/email/insertemail/insertemail.component";
import {UpdateemailComponent} from "@app/datamanagement/templates/email/updateemail/updateemail.component";
import {EmailviewComponent} from "@app/datamanagement/templates/email/emailview/emailview.component";
import {SmslistComponent} from "@app/datamanagement/templates/sms/smslist/smslist.component";
import {InsertsmsComponent} from "@app/datamanagement/templates/sms/insertsms/insertsms.component";
import {UpdatesmsComponent} from "@app/datamanagement/templates/sms/updatesms/updatesms.component";
import {SmsviewComponent} from "@app/datamanagement/templates/sms/smsview/smsview.component";
import {WhatsapplistComponent} from "@app/datamanagement/templates/whatsapp/whatsapplist/whatsapplist.component";
import {InsertwhatsappComponent} from "@app/datamanagement/templates/whatsapp/insertwhatsapp/insertwhatsapp.component";
import {UpdatewhatsappComponent} from "@app/datamanagement/templates/whatsapp/updatewhatsapp/updatewhatsapp.component";
import {WhatsappviewComponent} from "@app/datamanagement/templates/whatsapp/whatsappview/whatsappview.component";
import {ReportComponent} from "@app/report/report/report.component";
import {InsertsmscamapignComponent} from "@app/insights/campaign/insertsmscamapign/insertsmscamapign.component";
import {InsertwhatsappcamapignComponent} from "@app/insights/campaign/insertwhatsappcamapign/insertwhatsappcamapign.component";
import {ClonecampaignComponent} from "@app/insights/campaign/clonecampaign/clonecampaign.component";
import {TelegramComponent} from "@app/marketing/telegram/telegram.component";
import {FacebookComponent} from "@app/socialmedia/facebook/facebook.component";
import {InstagramComponent} from "@app/socialmedia/instagram/instagram.component";
import {LinkedinComponent} from "@app/socialmedia/linkedin/linkedin.component";
import {TwitterComponent} from "@app/socialmedia/twitter/twitter.component";
import {YoutubeComponent} from "@app/socialmedia/youtube/youtube.component";
import {Emailverification} from "@app/common/emailverification";

@NgModule({
    declarations: [
        AppComponent,
        LayoutComponent,
        HeaderComponent,
        FooterComponent,
        LoginComponent,
        BusinessComponent,
        ContactlistComponent,
        InsertcontactComponent,
        UpdatecontactComponent,
        ContactviewComponent,
        PaginationComponent,
        ExportcontactComponent,
        ImportcontactComponent,
        EmailComponent,
        SmsComponent,
        WhatsappComponent,
        TelegramComponent,
        EmaillistComponent,
        InsertemailComponent,
        UpdateemailComponent,
        EmailviewComponent,
        SmslistComponent,
        InsertsmsComponent,
        UpdatesmsComponent,
        SmsviewComponent,
        WhatsapplistComponent,
        InsertwhatsappComponent,
        UpdatewhatsappComponent,
        WhatsappviewComponent,
        ChangepasswordComponent,
        ProfileComponent,
        DashboardComponent,
        RedirectComponent,
        SettingsComponent,
        CountrylistComponent,
        InsertcountryComponent,
        UpdatecountryComponent,
        StatelistComponent,
        InsertstateComponent,
        UpdatestateComponent,
        CitylistComponent,
        InsertcityComponent,
        UpdatecityComponent,
        DocumenttypelistComponent,
        InsertdocumenttypeComponent,
        UpdatedocumenttypeComponent,
        CustomerlistComponent,
        InsertcustomerComponent,
        UpdatecustomerComponent,
        CustomerviewComponent,
        ExportcustomerComponent,
        ImportcustomerComponent,
        EmployerComponent,
        InsightdashboardComponent,
        DocumentsComponent,
        ParametersComponent,
        CampaignlistComponent,
        TemplatelistComponent,
        InserttemplateComponent,
        UpdatetemplateComponent,
        TemplateviewComponent,
        InsertcampaignComponent,
         UpdatecampaignComponent,
        InsertsmscamapignComponent,
        InsertwhatsappcamapignComponent,
        CampaignviewComponent,
        ClonecampaignComponent,
        FacebookComponent,
        InstagramComponent,
        LinkedinComponent,
        TwitterComponent,
        YoutubeComponent,
        EmployeelistComponent,
        InsertemployeeComponent,
        UpdateemployeeComponent,
        EmployeeviewComponent,
        BirthdaylistComponent,
        ReportComponent,
        WorkanniversarylistComponent,
        TurbobusinesscustomerComponent,
        SidemenuComponent,
        SidemenulayoutComponent
    ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpModule,
        HttpClientModule,
        FormsModule,
        RouterModule,
        routing,
        Ng2SearchPipeModule,
        // PaginationModule
        ValidationerrorModule,
        FroalaEditorModule.forRoot(), FroalaViewModule.forRoot(),

    ],
    providers: [
        TokenService,
        AuthGuard,
        DatePipe,
        Lookup,
        Localstorage,
        Securestorage,
        AlertService,
        PopupService,
        Emailverification
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
