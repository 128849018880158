import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {Title} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {Utility} from '@app/common/utility';

declare var $: any;


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

    constructor(private http: HttpClient, private title: Title) {
    }

    ngOnInit() {

        $('.navbar-collapse li.active').removeClass('active');
        $('#menubusinessstudio').addClass('active');

        this.title.setTitle('Octanics - Report');
    }

}
